import authServer from "@/services/auth.service";
import { RemoteCaller } from "./remoteCaller";

export class ServerHandler {
  constructor(context) {
    this.authserverRemote = new RemoteCaller(context, "authuser", 2);
    this.piserverRemote = new RemoteCaller(context, "piplanning", 6);
  }

  async getServerInfo(isUsingRest = false) {
    if (isUsingRest) {
      const res = await authServer.getServerInfo();
      const data = res.data.data;
      return {
        buildDate: data.build_date,
        FAOAuth2Callback: data.fa_oauth2_callback,
        isCloud: data.is_cloud,
        onpremVersion: data.onprem_version,
        serverID: data.server_id,
        version: data.version,
      };
    }

    const { info } = await this.authserverRemote.call("get_server_info");
    return info;
  }

  getPIServerInfo() {
    return this.piserverRemote.call("get_server_info");
  }
}
