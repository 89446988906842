import { v4 as uuid } from "uuid";
import { nextTick } from "vue";

import { autofocusStatus } from "./autofocus";

/**
 * Enable keyboard navigation through a list of '.list-item's
 * using the up- / down-arrow keys
 *
 * Modifiers:
 * select: Automatically selects the next/previous item on key press
 * no-wrap: Keyboard navigation will not wrap around the end of the list
 * soft-focus: Only focus on the current item if the focus is already on the list
 *             (if true, soft-focus-initial is effectively true as well)
 * soft-focus-initial: When the list initializes, don't pull focus to the first item
 * ignore-trigger: Key presses on the trigger element will be ignored
 * horizontal: Navigate horizontally (left/right arrow keys). Default is vertical (up/down)
 *
 * Value:
 * Value.selector: The css selector to identify the list items (default: '.list-item')
 *
 */
export function keyboardNavigation() {
  const navId = uuid();
  let listenerController = null;
  let currentIndex = 0;
  let activeElement = null;
  let listItems = null;
  let autoSelect = false;
  let wrapDisabled = false;
  let ignoreTrigger = false;
  let pullFocus = false;
  let pullFocusInitial = false;
  let horizontal = false;

  const defaultItemSelector = ".list-item";
  let itemSelector = defaultItemSelector;

  return {
    async inserted(el, binding) {
      autoSelect = binding.modifiers["select"];
      wrapDisabled = binding.modifiers["no-wrap"];
      ignoreTrigger = binding.modifiers["ignore-trigger"];
      pullFocus = !binding.modifiers["soft-focus"];
      pullFocusInitial = !binding.modifiers["soft-focus-initial"];
      horizontal = !!binding.modifiers["horizontal"];

      itemSelector = binding.value?.selector || defaultItemSelector;

      // Small delay to ensure any previous keyboard-navigation is unmounted first
      // (eg. when using TabbedModal with keyboard-navigation in multiple tabs)
      await nextTick();
      el.setAttribute("data-nav-id", navId);

      initListItems(el);
      initCurrent(el);

      if (!ignoreTrigger) {
        // Wait for any autofocus directive to finish
        let retries = 10;
        while (autofocusStatus.pending && retries > 0) {
          await nextTick();
          retries--;
        }
        // Add key listener to both the trigger and the list
        activeElement = document.activeElement;
        activeElement?.addEventListener("keydown", keyHandler);
      }

      el?.addEventListener("keydown", keyHandler);
      setCurrent(currentIndex, true);
    },

    componentUpdated(el) {
      initListItems(el);
      initCurrent(el);
    },

    unbind(el) {
      listenerController?.abort();

      if (!ignoreTrigger) {
        activeElement?.removeEventListener("keydown", keyHandler);
      }

      el?.removeEventListener("keydown", keyHandler);
      currentIndex = -1;

      el?.removeAttribute("data-nav-id");
    },
  };

  function initListItems(el) {
    listenerController?.abort();
    listenerController = new AbortController();
    listItems = el.querySelectorAll(
      `${itemSelector}:not(.static):not(.disabled)`
    );
    if (autoSelect) {
      initClickListeners();
    } else {
      initPointerListeners();
    }
  }

  /**
   * When the list initializes, either focus on the active (selected) item,
   * or focus on the first item if none are active
   */
  function initCurrent(el) {
    const active = el.querySelector(".active");
    if (active) {
      currentIndex = Array.from(listItems).indexOf(active);
    } else {
      currentIndex = 0;
    }
  }

  /**
   * Ensure the currentIndex gets updated when the user clicks on a list item
   */
  function initClickListeners() {
    listItems?.forEach((item, index) => {
      item.addEventListener("click", () => setCurrent(index), {
        signal: listenerController.signal,
        capture: true,
      });
    });
  }

  /**
   * Ensure the currentIndex is updated when the user hovers over a list item
   */
  function initPointerListeners() {
    listItems?.forEach((item, index) => {
      item.classList.add("no-hover");
      item.addEventListener("pointerenter", () => setCurrent(index), {
        signal: listenerController.signal,
      });
      item.addEventListener("focus", () => setCurrentIfDifferent(index), {
        signal: listenerController.signal,
      });
    });
  }

  /**
   * Set the current item if the given index is different from the current one
   * (avoids infinite loop when setting focus on the current item)
   */
  function setCurrentIfDifferent(index) {
    if (
      index !== currentIndex ||
      !currentItem()?.classList.contains("current")
    ) {
      setCurrent(index);
      simulatePointerEvent();
    }
  }

  async function setCurrent(index, initial = null) {
    currentItem()?.classList.remove("current");
    currentIndex = index;
    currentItem()?.classList.add("current");
    // the currentItem might not yet be correctly positioned (context menu)
    // wait for it not to scroll the board with the focus
    await nextTick();
    await nextTick();
    // Only move focus to the current item if the focus is already somewhere on the list
    // (or if in 'pull focus' mode)
    if (
      (initial ? pullFocusInitial && pullFocus : pullFocus) ||
      document.activeElement?.closest(`[data-nav-id="${navId}"]`)
    ) {
      currentItem()?.focus();
    }
  }

  function simulatePointerEvent() {
    const newItem = currentItem();
    if (newItem) {
      newItem.dispatchEvent(new PointerEvent("pointerenter"));
      if (autoSelect) {
        newItem.click();
      }
      newItem.scrollIntoView({ block: "nearest", behavior: "smooth" });
    }
  }

  function currentItem() {
    return listItems?.item(currentIndex);
  }

  /**
   * Return the wrapped or non-wrapped index, depending on the
   * given wrapDisabled modifier
   */
  function adjustIndex(index) {
    return wrapDisabled ? noWrap(index) : wrap(index);
  }

  /**
   * Return the given index if it's within 0 <= index <= listItems.length-1,
   * or give the nearest valid index (i.e. -1 --> 0, length --> length-1)
   */
  function noWrap(index) {
    return clamp(index, 0, listItems?.length ? listItems.length - 1 : 0);
  }

  function clamp(value, min, max) {
    return Math.min(Math.max(value, min), max);
  }

  /**
   * Return the given index if it's within the array of listItems,
   * or give the index that simulates the list wrapping (i.e. -1 --> last item)
   */
  function wrap(index) {
    const len = listItems?.length;
    return len === undefined ? -1 : (index + len) % len;
  }

  function keyHandler(event) {
    switch (event.key) {
      case !horizontal && "ArrowUp":
      case horizontal && "ArrowLeft":
        terminateEvent(event);
        setCurrent(adjustIndex(currentIndex - 1));
        simulatePointerEvent();
        break;
      case !horizontal && "ArrowDown":
      case horizontal && "ArrowRight":
        terminateEvent(event);
        setCurrent(adjustIndex(currentIndex + 1));
        simulatePointerEvent();
        break;
      case "Enter":
      case " ":
        terminateEvent(event);
        currentItem()?.click();
        break;
    }
  }

  function terminateEvent(event) {
    event.stopPropagation();
    event.preventDefault();
  }
}
