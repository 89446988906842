<script>
export default {
  data() {
    return {
      callingServer: false,
      serverError: "",
    };
  },
  methods: {
    serverCall(promise, ok, fail) {
      this.serverError = "";
      this.callingServer = true;
      return promise.then(
        (res) => {
          this.callingServer = false;
          if (res.success) {
            ok(res);
          } else {
            this.serverError = fail(res);
          }
        },
        (err) => {
          this.callingServer = false;
          this.serverError = fail(err);
        }
      );
    },
  },
};
</script>
