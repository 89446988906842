import * as localRepository from "@/components/session/sessionLocalStorageRepository";

export default class LinkService {
  constructor(sessionHandler, almHandler) {
    this.sessionHandler = sessionHandler;
    this.almHandler = almHandler;
  }

  async getStickyTypesWithAlmInfo() {
    const allAlmTypes = await this.almHandler.getAlmTypes();
    const stickyTypes = await this.sessionHandler.getStickyTypes();
    return stickyTypes.map((stickyType) => {
      const almTypes = allAlmTypes.filter(
        ({ id }) => id === stickyType.alm_type
      );
      const backlogProjectIds = almTypes
        .filter((almType) => !!almType.project_id && almType.project_id !== -1)
        .map((almType) => almType.project_id);
      return {
        id: stickyType._id,
        name: stickyType.name,
        issueType: almTypes?.[0]?.issue_type,
        backlogProjectIds:
          stickyType.origin_board_type === "backlog"
            ? backlogProjectIds
            : undefined,
      };
    });
  }

  async enrichStickyTypesWithAlmInfo(stickyTypes, teams) {
    const isSolutionTrain = localRepository.getSelectedArtIds([]).length > 1;
    const artProjectIds = isSolutionTrain
      ? await this.loadArtProjectIds(teams)
      : undefined;
    return stickyTypes.map((stickyType) => {
      const backlogProjectIds =
        artProjectIds ||
        (this.isValid(stickyType.project) ? [stickyType.project] : []);
      return {
        id: stickyType.frontend_id,
        name: stickyType.name,
        issueType: stickyType.issueType,
        backlogProjectIds:
          stickyType.origin_board_type === "backlog"
            ? backlogProjectIds
            : undefined,
      };
    });
  }

  async loadArtProjectIds(teams) {
    const artsMapping = await this.almHandler.getArtsMappingSources(
      unique(teams.map((team) => team.artId))
    );
    return unique(
      artsMapping.flatMap((mapping) => mapping.projects).map(({ id }) => id)
    );

    function unique(ids) {
      return Array.from(new Set(ids));
    }
  }

  async createLinkStickyTypes(stickyTypes, almIssueTypes, teams, projects) {
    const teamProjectIds = (
      await this.almHandler.getTeamProjects(teams.map((team) => team.id))
    ).map(({ id }) => "" + id);
    return stickyTypes
      .filter((stickyType) => this.isValid(stickyType.issueType))
      .map((stickyType) => {
        const almIssueType = almIssueTypes.find(
          (t) => t.id === stickyType.issueType
        );
        const projectIds = stickyType.backlogProjectIds || teamProjectIds;
        return {
          id: stickyType.id,
          almId: stickyType.issueType,
          name: stickyType.name,
          almName: almIssueType.name,
          projects: projectIds
            .filter(
              (projectId) => !!projects.find(({ id }) => id === projectId)
            )
            .map((projectId) => projects.find(({ id }) => id === projectId)),
        };
      });
  }

  isValid(id) {
    return id && id !== -1;
  }

  async getLinks(stickyTypes, issueLinkTypes) {
    const almLinks = await this.almHandler.getLinkTypes();
    const linkTypes = await this.sessionHandler.getLinkTypes();
    return linkTypes.reduce((result, linkType) => {
      const from = stickyTypes.find((s) => s.id === linkType.from_sticky_type);
      const to = stickyTypes.find((s) => s.id === linkType.to_sticky_type);
      // when a sticky type is not found (probably deleted), ignore the link
      if (from && to) {
        result.push(createLink(linkType, from, to));
      }
      return result;
    }, []);

    function createLink(linkType, from, to) {
      const almLink = almLinks.find((j) => j.pi_link_type_id === linkType.id);
      // almLink could be null via direct db access, currently not via this GUI
      const link =
        almLink &&
        issueLinkTypes.find((l) => l.issue_link_id === almLink.issue_link_id);
      return {
        id: linkType.id,
        almId: almLink && almLink.id,
        from,
        to,
        type: {
          issue_link_id: almLink && almLink.issue_link_id,
          link_type: almLink && almLink.type,
          name: almLink
            ? link
              ? link.name
              : almLink.issue_link_id
            : linkType.id,
        },
        errors: [],
        checking: false,
      };
    }
  }
}

export function linkRefsFromIdToAlmType(links, stickies) {
  copyLinkRefs(links);
  changeLinkRefs(links, stickies, "_id", "alm_type");
}

export function linkRefsFromAlmTypeToBackendId(links, stickies) {
  copyLinkRefs(links);
  changeLinkRefs(links, stickies, "alm_type", "backendId");
}

function copyLinkRefs(links) {
  links.forEach((link) => {
    link.from._id = link.from.id;
    link.to._id = link.to.id;
  });
}

function changeLinkRefs(links, stickies, fromProp, toProp) {
  links.forEach((link) => {
    link.from.id = stickies.find((s) => s[fromProp] === link.from._id)[toProp];
    link.to.id = stickies.find((s) => s[fromProp] === link.to._id)[toProp];
  });
}
