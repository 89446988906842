import { nextTick } from "vue";

// Small shared state to let other components know if autofocus is pending
export const autofocusStatus = { pending: false };

/**
 * Autofocuses to the element on mount and sets tabindex=0
 * To autofocus later/conditionally, pass a value of false
 *
 * @param value: if false, autofocus will not initialize (note: with any other value, including undefined, it will initialize)
 */
export function autofocus() {
  const initAutofocus = (el) => {
    autofocusStatus.pending = true;
    nextTick(() => {
      if (el.nodeName === "LABEL") {
        el.click();
      } else {
        el.tabIndex = el.tabIndex ?? 0;
        el.focus();
      }
      autofocusStatus.pending = false;
    });
  };
  return {
    // If the value is not false, autofocus
    inserted(el, { value }) {
      if (value === false) {
        return;
      }

      initAutofocus(el);
    },

    // If the value changes to something other than false, autofocus
    componentUpdated(el, { value, oldValue }) {
      if (oldValue === false && value !== false) {
        initAutofocus(el);
      }
    },
  };
}
