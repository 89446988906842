import autobahn from "autobahn";
import {
  authAPIUrl,
  intercomApi,
  intercomId,
  isCloud,
  wampUrl,
} from "@/environment";
import AuthService from "@/services/auth.service";
import { captureExceptionWithContext, resetUser, setUser } from "@/sentry";
import { dateToUnixTimestamp } from "@/utils";
import { identifyUser, trackEvent } from "@/analytics/track";
import { appLoaded } from "@/analytics/events";
import { initFeatureToggles } from "@/featureToggles";
import { UserHandler } from "@/handlers/userHandler";
import { UserService } from "@/services/user.service";

export default function wsconnect({ store } = {}) {
  return new Promise((resolve, reject) => {
    const connection = new autobahn.Connection({
      url: wampUrl,
      realm: "realm1",
      authmethods: ["anonymous"],
    });

    connection.onopen = async (_session, details) => {
      store.commit("setUserInfo", details);
      store.commit("setConnection", connection);
      store.commit("available");

      const { data: user } = await new UserService(authAPIUrl).getCurrentUser();
      store.commit("setUser", user);
      setUser(user);

      if (details.authrole !== "public") {
        const license = await getLicense(store);
        store.commit(
          "expireDays",
          Math.round(
            (license.expiryDate - dateToUnixTimestamp(new Date())) /
              (60 * 60 * 24)
          )
        );
        store.commit(license.sso ? "enableSSO" : "disableSSO");
        store.commit("onPrem", license.onprem);

        store.commit("license", license);

        initIntercom(user, license);
        identifyUser(user, license);
        initFeatureToggles(user.company);

        trackEvent(appLoaded());
      }

      resolve(connection);
    };

    // This callback function cannot be async because autobahnjs doesn't support Promises as return value
    connection.onclose = (reason, details) => {
      resetUser();
      window.Intercom("shutdown");

      switch (reason) {
        case "unreachable":
          store.commit("notAvailable");
          return resolve();
        case "lost":
          // Try to reconnect by returning falsy value
          // https://github.com/crossbario/autobahn-js/blob/master/doc/reference.md#connection-callbacks
          store.commit("notAvailable");
          return false;
        case "closed":
          if (!details.reason) {
            return true;
          }
          if (details.reason === "ch.rentouch.auth.expired_access_token") {
            return (async function () {
              try {
                await AuthService.refreshToken();
              } catch (err) {
                return captureErrorAndReject("Failed to refresh token", err);
              }
              return resolve(wsconnect({ store }));
            })();
          }
          if (
            details.reason === "ch.rentouch.auth.cookies_not_found" ||
            details.reason === "ch.rentouch.auth.denied" ||
            details.reason === "jwtRevoked"
          ) {
            return reject(
              new Error(`Connection closed, reason: ${details.reason}`)
            );
          }
          return resolve();
      }

      // For all other reasons capture error and reject
      return captureErrorAndReject(reason, details);

      function captureErrorAndReject(reason, details) {
        const error = new Error(`Connection closed, reason: ${reason}`);

        if (details?.response?.status !== 401) {
          captureExceptionWithContext(error, {
            error: { ...details?.data },
            details,
          });
        }

        reject(error);
        return false;
      }
    };

    connection.open();
  });
}

function getLicense(store) {
  const userHandler = new UserHandler(store.getters, 2);

  return userHandler.getLicenseDetails();
}

function initIntercom(user, license) {
  if (isCloud && intercomId && intercomApi && license.tracking) {
    window.Intercom = null; // delete existing intercom (dummy) instance
    window.loadIntercom(intercomId, intercomApi);
  }

  window.Intercom("boot", {
    email: user.email,
    name: user.name,
    user_id: user.id,
    user_hash: user.hash,
    role: intercomRoles[user.role],
    language_override: user.preferredLanguage,
    avatar: {
      type: "avatar",
      image_url: user.imageUrl || "",
    },
    company: {
      company_id: user.company,
      name: user.company,
      plan: license.plan,
    },
  });
}

const intercomRoles = {
  observer: "Observer",
  user: "Member",
  admin: "Admin",
  planning_interval_admin: "PI Admin",
};
