<template>
  <avatar
    :text="firstNameLetter"
    :class="{ large: isLarge }"
    aria-label="User Avatar"
  >
    <img v-if="imageUrl" :src="imageUrl" alt="Profile Image" />
  </avatar>
</template>

<script>
import Avatar from "@/components/Avatar.vue";

export default {
  components: {
    Avatar,
  },
  props: {
    isLarge: {
      type: Boolean,
      default: false,
    },
    user: {
      type: Object,
      required: true,
    },
  },
  computed: {
    imageUrl() {
      return this.user.imageUrl;
    },
    firstNameLetter() {
      const letter = this.user.name || this.user.email;
      return letter.substring(0, 1).toUpperCase();
    },
  },
};
</script>
