<script>
export default {
  created() {
    document.addEventListener("keyup", (e) => {
      if (e.key === "Escape") {
        this.close();
      }
    });
  },
  methods: {
    close() {
      $(".modal").hide();
      this.$emit("closed");
    },
    ok() {
      $(".modal").hide();
    },
  },
};
</script>
