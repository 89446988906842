import { createApiClient } from "@/services/apiClient";

const baseURL = "v1/roles";

export class RoleService {
  constructor(authAPIUrl) {
    this.apiClient = createApiClient(authAPIUrl);
  }

  async getRoles() {
    const rsp = await this.apiClient.get(`${baseURL}`);
    return {
      success: rsp.data.status === "success",
      data: rsp.data.data,
    };
  }
}
