import { RemoteCaller } from "./remoteCaller";

export class SupportHandler {
  constructor(context, apiVersion = 6) {
    this.remote = new RemoteCaller(
      { company: null, session: context.session },
      "piplanning",
      apiVersion,
      "support"
    );
  }

  getTar() {
    return this.remote.progressiveCall("get_tar");
  }
}
