import { ensureSuccessResponse } from "./remoteCaller";

export function almHandlerAdo(remote, connectionID, almSessionId) {
  function getIssueTypes() {
    return remote
      .call("get_work_item_types", [connectionID])
      .then((almIssueTypes) => {
        ensureSuccessResponse(almIssueTypes);
        return almIssueTypes.map((almIssueType) => ({
          id: almIssueType.referenceName,
          name: almIssueType.name,
        }));
      });
  }

  return {
    name: "Azure DevOps",
    supportsLinks: true,
    checkStatus: () => {
      return Promise.resolve({ working: true });
    },
    getIssueLinkTypes: () => {
      return remote
        .call("get_workitem_link_types", [connectionID])
        .then((links) => {
          return links.map((l) => ({
            link_type: l.name,
            issue_link_id: l.referenceName,
            name: l.name,
          }));
        });
    },
    getMapping: () => {
      return remote.call("get_team_mapping", [connectionID]).then((mappings) =>
        mappings.map((mapping) => ({
          user_id: mapping.team_id,
          sources: mapping.sources.map((m) => ({
            id: m.id,
            areaPath: m.area_path,
          })),
        }))
      );
    },
    getProjects: () => {
      return remote
        .call("get_projects", [connectionID])
        .then((projects) =>
          projects.map((project) => ({ id: project.id, name: project.name }))
        );
    },
    getProject: () => {
      return remote.call("get_project", [], { connection_id: connectionID });
    },
    setProject: (projectId) => {
      return remote.call("set_project", [connectionID, projectId]);
    },
    setPat: (pat) => {
      return remote.call("set_pat", [], {
        pat,
        connection_id: connectionID,
      });
    },
    getWebhookUrl: () => {
      return remote.call("get_webhook_endpoint", [], {
        connection_id: connectionID,
      });
    },
    getIssueTypes,
    getPortfolioIssueTypes: getIssueTypes,
    getIssueFields(issueType) {
      return remote.call("get_work_item_fields", [], {
        connection_id: connectionID,
        ref_name: issueType,
      });
    },
    checkLinkType: () => {
      return Promise.resolve({ from_errors: [], to_errors: [] });
    },
    getAlmTypes: () => {
      return remote
        .call("session.get_alm_types", [almSessionId])
        .then((almTypes) =>
          almTypes.map((almType) => ({
            id: almType.id,
            issue_type: almType.ado_work_item_type,
            project_id: null,
            wsjf: () => almType.ado_wsjf_field,
            storyPoints: almType.ado_sp_field,
            fields: (almType.ado_fields || []).map((field) => ({
              type: { key: field.referenceName, operation: field.operator },
              value: field.value,
              error: null,
              validating: false,
            })),
          }))
        );
    },
    getTeamBoardAlmType: (almType) => {
      return almType
        ? {
            issueType: almType.issue_type,
            storyPointField: almType.storyPoints,
          }
        : {};
    },
    getBacklogBoardAlmType: (_, almType) => {
      return almType
        ? { selectedField: almType.wsjf(), adoFields: almType.fields }
        : {};
    },
    getLinkTypes: () => {
      return remote
        .call("session.get_link_types", [almSessionId])
        .then((almLinkTypes) =>
          almLinkTypes.map((almLinkType) => ({
            pi_link_type_id: almLinkType.pi_link_type_id,
            issue_link_id: almLinkType.ado_relation,
            id: almLinkType.id,
            type: almLinkType.ado_relation_name,
          }))
        );
    },
    deleteLinkType: (link) => {
      return remote.call("session.delete_link_type", [link.almId]);
    },
    updateLinkType: (link) => {
      return remote.call("session.update_link_type", [
        link.almId,
        {
          ado_relation: link.type.issue_link_id,
          ado_relation_name: link.type.link_type,
        },
      ]);
    },
    createLinkType: (link) => {
      return remote.call("session.create_link_type", [
        almSessionId,
        link.id,
        link.type.issue_link_id,
        link.type.link_type,
      ]);
    },
    getTags: () => {
      return Promise.resolve([]);
    },
    async validatePath(path) {
      const name = path.area ? "area" : "iteration";
      const param = {
        connection_id: connectionID,
        [name + "_path"]: path[name],
      };
      const res = await remote.call(`validate_${name}_path`, [], param);
      if (typeof res === "object" && "success" in res) {
        return res;
      }
      return res
        ? { success: true, value: res }
        : { success: false, error: `Invalid ${name} path` };
    },
    loadSourceSprints: (source) => {
      source.error = null;
      source.validating = false;
      return Promise.resolve();
    },
    setSourceSprint: async (source, sessionSource) => {
      source.iterationPath =
        (sessionSource.iteration_path || sessionSource.iterationPath) ?? null;
    },
    async loadAlmItemTypes(dry_run = false) {
      return await remote.call("item.load_alm_item_types", [connectionID], {
        dry_run,
      });
    },
  };
}
