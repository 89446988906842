<!-- Used by vue-form-generator in pi-admin/src/pages/SetupPage.vue -->
<template>
  <div class="checkbox-wrap">
    <input
      :id="schema.model"
      v-model="value"
      type="checkbox"
      :autocomplete="schema.autocomplete"
      :disabled="disabled"
      :name="schema.inputName"
      :class="schema.fieldClasses"
      :readonly="schema.readonly"
    />
    <!-- eslint-disable-next-line-->
    <label :for="schema.model" v-html="schema.checkboxLabel"></label>
  </div>
</template>

<script>
import { abstractField } from "vue-form-generator";

export default {
  mixins: [abstractField],
};
</script>

<style lang="scss" scoped>
@import "@/_variables-legacy.scss";

.checkbox-wrap {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 60px;
  font-size: 1.25rem;
  padding-right: 24px;
  position: relative;

  label {
    padding-left: 3px;
  }

  input {
    -webkit-appearance: none;
    -moz-appearance: none;
    background-color: $whiteColor;
    border: 3px solid $darkBlueColor;
    position: relative;
    width: 19px;
    height: 19px;
    min-width: 19px;
    box-sizing: border-box;
    margin-top: -3px;
  }

  input:checked {
    background: linear-gradient(
        135deg,
        transparent 0px,
        transparent 6px,
        $darkBlueColor 7px,
        $darkBlueColor 8px,
        transparent 9px
      ),
      linear-gradient(
        225deg,
        transparent 0px,
        transparent 6px,
        $darkBlueColor 7px,
        $darkBlueColor 8px,
        transparent 9px
      );
    background-size: 11px;
    background-position: 1px 1px;
    background-repeat: no-repeat;
  }
}
</style>
