<template>
  <div
    :class="{
      [$style['select-wrap']]: true,
      [$style.loading]: loading,
    }"
  >
    <select
      v-bind="$attrs"
      :id="id"
      v-model="selectedValue"
      class="reset"
      :multiple="multiple"
      v-on="listeners"
    >
      <template v-if="placeholder">
        <option v-if="selectedValue === ''" value="" disabled hidden>
          {{ placeholder }}
        </option>
      </template>

      <slot>
        <option
          v-for="option in selectOptions"
          :key="option.id || option.value || option.label"
          :value="option.value"
          :disabled="option.disabled"
        >
          {{ option.label }}
        </option>
      </slot>
    </select>
  </div>
</template>

<script>
export default {
  inheritAttrs: false,
  model: {
    event: "change",
  },
  props: {
    options: {
      type: Array,
      default: () => [],
    },
    value: {
      type: [String, Array, Number],
      default: "",
    },
    multiple: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    placeholder: {
      type: String,
      default: "",
    },
    id: {
      type: String,
      required: false,
    },
  },
  computed: {
    selectOptions() {
      return this.options.map((option) => {
        if (option && (option.label || option.value)) {
          return option;
        }
        return { label: option, value: option };
      });
    },
    listeners() {
      const listeners = {
        ...this.$listeners,
      };
      delete listeners.change;
      return listeners;
    },
    selectedValue: {
      get() {
        return this.value;
      },
      set(newValue) {
        this.$emit("change", newValue);
      },
    },
  },
};
</script>

<style lang="scss" module>
@import "@/_variables-legacy.scss";

.select-wrap {
  align-items: center;
  display: grid;
  font-size: 1.25rem;
  grid-template-areas: "select";
  line-height: 2;
  background-color: $whiteColor;

  select {
    height: 60px;
    outline: none;
    padding-left: 50px !important;
    border-bottom: 3px solid $darkBlueColor !important;
    cursor: pointer !important;

    .default-option {
      color: $greyColor;
    }
  }
}

:global(.error) {
  .select-wrap select,
  &.select-wrap select {
    border-bottom-color: $redColor;
  }
}

:global(.warning) {
  .select-wrap select,
  &.select-wrap select {
    border-bottom-color: $orangeColor;
  }
}

.select-wrap::after {
  content: "\25BC"/ ""; // down arrow / aria-hidden
  color: $darkBlueColor;
  justify-self: end;
  padding-left: 0.5em;
  padding-right: 0.5em;
  pointer-events: none;
  background-color: inherit;
}

.loading.select-wrap::after {
  background-image: url(~@/assets/spinner.gif);
  background-size: 2em;
  content: "";
  height: 2em;
  width: 1em;
}

select,
.select-wrap::after {
  grid-area: select;
}

.select-wrap:focus-within {
  box-shadow: 0px 1px 4px -1px $darkBlueColor;
}
</style>

<docs>
  ## Examples

  A simple select:
  ```jsx
  <base-select :options="['One', 'Two', 'Three', 'A very long option that shows what happens when the description overflows the container width']" />
  ```

  A select with differing value and label:
  ```jsx
  <base-select :options="[{value: 'one', label: 'First'}, {value: 'two', label: 'Second'}]" />
  ```

  A select with disabled option:
  ```jsx
  <base-select :options="[{value: 'one', label: 'First', disabled: true}, {value: 'two', label: 'Second'}]" />
  ```

  A select with placeholder:
  ```jsx
  <base-select placeholder="Select option" :options="[{value: 'one', label: 'First'}, {value: 'two', label: 'Second'}]" />
  ```

  With `v-model`:
  ```vue
  <template>
    <div>
      Selected color: {{selectedColor}}
      <base-select :options="['Green', 'Red', 'Blue', 'Yellow']" v-model="selectedColor" />
    </div>
  </template>

  <script>
  export default {
    data() {
      return { selectedColor: 'Blue' }
    }
  }
  </script>
  ```

  When loading it shows a spinner:
  ```jsx
  <base-select :options="['One', 'Two', 'Three', 'A very long option that shows what happens when the description overflows the container width']" loading />
  ```

  When given the `error` class either on the element itself or a parent:

  ```jsx
  <base-select :options="['One', 'Two', 'Three']" class="error" />
  ```

  ```jsx
  <div class="error">
    <base-select :options="['One', 'Two', 'Three']" />
  </div>
  ```
</docs>
