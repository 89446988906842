<template>
  <div>
    <div
      v-if="!onPrem && expireDays > 0 && expireDays < 30"
      :class="$style['expired']"
    >
      Your licenses expire in {{ expireDays }} days.
      <span v-if="isAdmin">
        <router-link :to="{ name: isTraining ? 'trainingBilling' : 'billing' }"
          >Extend now</router-link
        >
      </span>
      <span v-else> Please notify the RTE. </span>
    </div>
    <div
      v-if="expireDays != null && expireDays <= 0 && !onPrem"
      :class="$style['expired']"
    >
      Your licenses have expired.
      <span v-if="isAdmin">
        <router-link :to="{ name: isTraining ? 'trainingBilling' : 'billing' }"
          >Renew here</router-link
        >
      </span>
      <span v-else> Please notify the RTE. </span>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapState } from "vuex";

export default {
  name: "LicenseExpired",
  computed: {
    ...mapGetters(["isTraining", "isAdmin"]),
    ...mapState(["expireDays", "onPrem"]),
  },
};
</script>

<style lang="scss" module>
@import "@/_variables-legacy.scss";

.expired {
  position: fixed;
  right: 0;
  top: $header-height;
  padding: 0.375rem 1.625rem;
  background-color: $darkRedColor;
  color: $whiteColor;

  a,
  a:visited {
    color: $whiteColor;
    text-decoration: underline;
  }
}
</style>
