<template>
  <router-link
    v-if="allowed"
    v-slot="{ href, navigate, isActive }"
    :to="to"
    custom
  >
    <!-- Click handler is triggered by keyboard as well -->
    <!-- eslint-disable-next-line vuejs-accessibility/no-static-element-interactions vuejs-accessibility/click-events-have-key-events -->
    <li
      :class="[$style['sidebar-link'], { [$style['active']]: isActive }]"
      @click="handleClick"
    >
      <a
        :aria-current="isActive ? 'page' : 'false'"
        :href="href"
        @click="navigate"
      >
        <slot />
      </a>
    </li>
  </router-link>
  <li
    v-else-if="notAllowedTitle"
    :class="$style['sidebar-link']"
    :title="`Disabled: ${notAllowedTitle}`"
  >
    <div aria-hidden="true">
      <slot />
    </div>
  </li>
</template>

<script>
import { sidebarItemClicked } from "@/analytics/events";
import { trackEvent } from "@/analytics/track";
import { isRouteAllowed } from "@/router";
import { toKebabCase } from "@/utils/text";

export default {
  name: "SidebarLink",
  props: {
    to: {
      type: Object,
      required: true,
    },
    notAllowedTitle: {
      type: String,
      required: false,
    },
  },
  computed: {
    allowed() {
      const route = this.$router.matcher.match(this.to);
      return isRouteAllowed(route);
    },
  },
  methods: {
    handleClick() {
      trackEvent(sidebarItemClicked(toKebabCase(this.to.name)));
    },
  },
};
</script>

<style lang="scss" module>
@import "@/_variables-legacy.scss";

.sidebar-link {
  display: block;
  padding: 0;
  margin: 0;
  width: 100%;
  border-radius: 8px;

  > * {
    display: inline-flex;
    padding: 0.75rem 1rem;
    width: calc(100% - 2rem);
    font-weight: bold;
    color: $greyColor;
    align-items: center;
    gap: 0.75rem;
  }

  a {
    color: $blackColor;

    &:hover {
      background-color: rgba($purpleColor, 0.06);
      color: $purpleColor;
    }
  }

  &.active {
    background: rgba($purpleColor, 0.06);

    a {
      color: $purpleColor;
    }
  }
}
</style>
