import { createApiClient } from "@/services/apiClient";

const baseLicenseUrl = "v1/license";

export class LicenseService {
  constructor(authAPIUrl) {
    this.apiClient = createApiClient(authAPIUrl);
  }

  async getLicense() {
    const rsp = await this.apiClient.get(baseLicenseUrl);

    return {
      data: rsp.data.data,
      success: rsp.data.status === "success",
    };
  }

  async getOnPremKey() {
    const rsp = await this.apiClient.get(`${baseLicenseUrl}/onprem-key`);

    return {
      data: rsp.data.data.key,
      success: rsp.data.status === "success",
    };
  }

  async updateOnPremKey(key) {
    const rsp = await this.apiClient.patch(`${baseLicenseUrl}/onprem-key`, {
      key: key,
    });

    return {
      success: rsp.data.status === "success",
    };
  }
}
