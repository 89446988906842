import { RemoteCaller } from "./remoteCaller";

export class TraceHandler {
  constructor(context, apiVersion = 1) {
    this.remote = new RemoteCaller(context, "trace", apiVersion);
  }

  getAuditLogs(start, end) {
    return this.remote.progressiveCall("get_auditlogs", [start, end]);
  }
}
