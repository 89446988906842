<template>
  <div class="base-switch" :class="{ disabled }">
    <label class="switch">
      <input
        type="checkbox"
        v-bind="$attrs"
        :checked="checked"
        :disabled="disabled"
        v-on="listeners"
      />
      <span class="slider round"></span>
    </label>
  </div>
</template>
<script>
export default {
  inheritAttrs: false,
  model: {
    prop: "checked",
    event: "change",
  },
  props: {
    checked: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    listeners() {
      return {
        ...this.$listeners,
        change: (event) => this.$emit("change", event.target.checked),
      };
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/_variables-legacy.scss";

.switch {
  position: relative;
  display: inline-block;
  width: 54px;
  height: 30px;

  input {
    opacity: 0;
    width: 0;
    height: 0;
  }

  &:focus-within {
    outline: 2px solid $darkBlueColor;
  }
}

.slider {
  position: absolute;
  cursor: pointer;
  inset: 0;
  background-color: $greyColor;
  transition: 0.4s;

  input:checked + & {
    background-color: $darkBlueColor;
  }

  .disabled & {
    background-color: $greyColor;
    cursor: not-allowed;
  }

  &::before {
    position: absolute;
    content: "";
    height: 20px;
    width: 20px;
    left: 6px;
    bottom: 5px;
    background-color: $whiteColor;
    transition: 0.4s;

    input:checked + & {
      transform: translateX(22px);
    }
  }

  &.round {
    border-radius: 34px;

    &::before {
      border-radius: 50%;
    }
  }
}
</style>
