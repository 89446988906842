import { authAPIUrl } from "@/environment";
import { CompanyService } from "@/services/company.service";
import { RoleService } from "@/services/role.service";
import { mapUser, UserService } from "@/services/user.service";
import { RemoteCaller } from "./remoteCaller";

export class UserHandler {
  constructor(context, apiVersion = 3, isUsingRest = false) {
    this.adminRemote = new RemoteCaller(context, "auth", apiVersion, "users");
    this.userRemote = new RemoteCaller(context, "authuser", apiVersion);
    this.user2Remote = new RemoteCaller(context, "authuser", 2);
    this.isUsingRest = isUsingRest;
    this.userService = new UserService(authAPIUrl);
    this.roleService = new RoleService(authAPIUrl);
    this.companyService = new CompanyService(authAPIUrl, context.company);
  }

  createUser(email, name, role) {
    if (this.isUsingRest) {
      return this.userService.createUser(name, email, role);
    }
    return this.adminRemote.call("create_user", [email, role], {
      name,
    });
  }

  async getUsers(next_page = 0) {
    if (this.isUsingRest) {
      return this.userService.getUsers(next_page);
    }
    const response = await this.adminRemote.call("get_users", [], {
      cursor: next_page,
    });
    const trans = this._transform_response(response);
    return { ...trans, data: trans.data?.map(mapUser) };
  }

  async getUser(id) {
    if (this.isUsingRest) {
      return this.userService.getUser(id);
    }
    const response = await this.adminRemote.call("get_user_by_id", [id]);
    const trans = this._transform_response(response);
    return { ...trans, data: mapUser(trans.data) };
  }

  getTeamsOfUser(id) {
    if (this.isUsingRest) {
      return this.userService.getTeams(id);
    }
    return this.adminRemote.call("get_teams_of_user", [id]);
  }

  getOrgEntitiesOfUser(id) {
    return this.userService.getOrgEntities(id);
  }

  async getRoles() {
    if (this.isUsingRest) {
      const roles = await this.roleService.getRoles();
      return { data: roles.data.results };
    }
    return this.adminRemote.call("get_roles");
  }

  /**
   * Updates a user's information
   * @param {string} userId - The ID of the user to update
   *
   * @param {string} data.email - The user's email address
   * @param {string} data.name - The user's name
   * @param {string} data.role - The user's role
   * @param {string} data.preferredLanguage - The user's preferred language
   */
  updateUser(userId, data) {
    if (this.isUsingRest) {
      return this.userService.updateUser(userId, data);
    }

    const { preferredLanguage, ...rest } = data;
    if (preferredLanguage) {
      rest.preferred_language = preferredLanguage;
    }

    return this.adminRemote.call("update_user", [], {
      user_id: userId,
      ...rest,
    });
  }

  deleteUser(id) {
    if (this.isUsingRest) {
      return this.userService.deleteUser(id);
    }
    return this.adminRemote.call("delete_user", [id]);
  }

  changePassword(userId, newPassword) {
    if (this.isUsingRest) {
      return this.userService.changePassword(userId, newPassword);
    }
    return this.adminRemote.call("change_password", [userId, newPassword]);
  }

  resetPassword(id) {
    if (this.isUsingRest) {
      return this.userService.resetPassword(id);
    }
    return this.adminRemote.call("forgot_password", [id]);
  }

  search(email, role = "") {
    if (this.isUsingRest) {
      return this.userService.search(email, role);
    }
    return this.adminRemote.call("search", [email, role]);
  }

  _transform_response(response) {
    if (typeof response === "object" && "success" in response) {
      return response;
    } else {
      return { success: true, data: response };
    }
  }

  async getCurrentUser() {
    if (this.isUsingRest) {
      return await this.userService.getCurrentUser();
    }

    // TODO: unify
    const res = await this.userRemote.call("get_current_user");
    return { ...res, data: mapUser(res.data) };
  }

  resetPassoword(userId) {
    if (this.isUsingRest) {
      return this.userService.resetPassword(userId);
    }
    return this.userRemote.call("request_password_reset");
  }

  getLicenseDetails() {
    if (this.isUsingRest) {
      return this.companyService.getLicense();
    }
    return this.user2Remote.call("get_license_details");
  }
}
