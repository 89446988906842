<template>
  <!-- eslint-disable-next-line vuejs-accessibility/click-events-have-key-events vuejs-accessibility/no-static-element-interactions -->
  <div
    v-if="isOpen"
    v-focus-trap
    :class="{
      [$style.modal]: true,
      [$style.wide]: wide,
    }"
    role="dialog"
    aria-modal="true"
    aria-labelledby="base-modal-title"
    :aria-description="description"
    @click.self="close()"
  >
    <div :class="$style['modal-content']">
      <button
        v-if="showClose"
        :class="$style['close-button']"
        aria-label="Close"
        @click="close"
      >
        &times;
      </button>
      <div ref="base-modal-title" tabindex="-1" :class="$style['modal-header']">
        <h1 v-if="title" id="base-modal-title" v-autofocus>{{ title }}</h1>
      </div>
      <div :class="$style['modal-body']">
        <!-- @slot The main content to display in the modal. -->
        <slot></slot>
      </div>
      <!-- @slot The action buttons at the bottom of the modal. -->
      <slot name="actions"></slot>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    /**
     * A title to display at the top of the modal.
     */
    title: {
      type: String,
      default: undefined,
    },
    /**
     * Determines whether the modal is currently open or not.
     */
    isOpen: {
      type: Boolean,
      required: true,
    },
    /**
     * Whether to display a wide modal for larger content. If false, the modal
     * will be small, ideal for a small informational content.
     */
    wide: {
      type: Boolean,
      default: false,
    },
    /**
     * Show close button ( X ) for the component
     */
    showClose: {
      type: Boolean,
      default: true,
    },
    /**
     * The aria-description for the modal dialog. If passed as an empty string (""),
     * JAWS (screen reader) will read the entire content of the modal when it opens.
     * This should only be an empty string for short modals (eg. delete confirmation)
     */
    description: {
      type: String,
      required: true,
    },
  },
  watch: {
    isOpen(newValue) {
      if (newValue) {
        this.$nextTick(() => {
          this.$refs["base-modal-title"]?.focus();
        });
      }
    },
  },
  created() {
    const onEscape = (e) => {
      if (this.isOpen && e.keyCode === 27) {
        this.close();
      }
    };
    document.addEventListener("keydown", onEscape);
    this.$once("hook:destroyed", () => {
      document.removeEventListener("keydown", onEscape);
    });
  },
  methods: {
    close() {
      /**
       * Notify that the user wants to close the modal.
       */
      this.$emit("close");
    },
  },
};
</script>

<style lang="scss" module>
@import "@/_variables-legacy.scss";

.modal {
  text-align: center;
  position: fixed;
  z-index: 3;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgba($blackColor, 0.5);
  animation-name: fadeIn;
  animation-duration: 0.4s;
}

.modal-content {
  position: fixed;
  top: 25%;
  left: 50%;
  transform: translate(-50%, -25%);
  width: 600px;
  max-width: 100%;
  max-height: 100%;
  background-color: $lightGreyColor;
  overflow: hidden;
  animation-name: slideIn;
  animation-duration: 0.4s;
  padding: 10px;

  .wide & {
    width: 90%;
    left: 5%;
    margin-left: auto;
    top: 10%;
    bottom: 10%;
    transform: none;
  }

  .medium & {
    width: 850px;
  }

  .close-button {
    position: absolute;
    top: 8px;
    right: 8px;
    color: $darkGreyColor;
    background-color: $lightGreyColor;
    font-size: 32px;
    font-weight: bold;
    border: 0;
    line-height: 100%;
    padding: 5px 8px;
    z-index: 1;

    &:hover,
    &:focus {
      color: $darkBlueColor;
      text-decoration: none;
      cursor: pointer;
    }

    &:focus-visible {
      outline: revert;
    }
  }

  .modal-header {
    position: relative;
    margin: 16px 0;
  }

  .modal-body {
    margin-bottom: 16px;
    color: $darkGreyColor;

    & > * {
      font-size: $font-normal;
    }
  }

  h1 {
    font-size: $font-large;
    font-weight: 400;
    margin: 0;
    max-width: 80%;
    display: inline-block;
  }
}

@keyframes slideIn {
  from {
    top: 100vh;
    opacity: 0;
  }
  to {
    top: 25%;
    opacity: 1;
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
</style>

<docs>
  The base modal component to display modal dialogs.

  ```vue
  <template>
    <div>
      <base-modal :isOpen="showModal" @close="showModal = false">
        This is the main content
      </base-modal>
      <base-button @click="showModal = true">Show modal</base-button>
    </div>
  </template>
  <script>
  export default {
    data() {
      return {
        showModal: false,
      };
    },
  };
  </script>
  ```

  With a title:
  ```vue
  <template>
    <div>
      <base-modal :isOpen="showModal" @close="showModal = false" title="Hello, World!">
        This is the main content
      </base-modal>
      <base-button @click="showModal = true">Show modal</base-button>
    </div>
  </template>
  <script>
  export default {
    data() {
      return {
        showModal: false,
      };
    },
  };
  </script>
  ```

  With additional buttons:
  ```vue
  <template>
    <div>
      <base-modal :isOpen="showModal" @close="showModal = false">
        This is the main content
        <template v-slot:actions>
          <base-button>Confirm</base-button>
        </template>
      </base-modal>
      <base-button @click="showModal = true">Show modal</base-button>
    </div>
  </template>
  <script>
  export default {
    data() {
      return {
        showModal: false,
      };
    },
  };
  </script>
  ```
</docs>
