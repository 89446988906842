<template>
  <base-button
    :validate-form="validateForm"
    v-bind="$attrs"
    :disabled="disabled"
    @click="handleClick"
  >
    <!-- @slot The text of the button -->
    <slot>Save</slot>
  </base-button>
</template>
<script>
import { mapActions } from "vuex";
import BaseButton from "@/components/BaseButton";

export default {
  components: {
    BaseButton,
  },
  props: {
    /**
     * When set to `true`, the button will be disabled.
     */
    disabled: {
      type: Boolean,
      default: false,
    },
    successMessage: {
      type: String,
      default: "Operation completed successfully.",
    },
    validateForm: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    ...mapActions("toast", ["showFeedback"]),
    extractHandler(callback) {
      // Warning: Here be dragons!
      // This is leveraging Vue internals. As such it can possibly fail in a
      // future Vue version.
      // cfr.: https://github.com/vuejs/vue/blob/b51430f598b354ed60851bb62885539bd25de3d8/src/core/vdom/helpers/update-listeners.js#L36
      if ("fns" in callback && !Array.isArray(callback.fns)) {
        return callback.fns;
      }
      return callback;
    },
    async handleClick(event) {
      if (!this.$listeners.click) {
        return;
      }
      const click = this.extractHandler(this.$listeners.click);
      return this.showFeedback({
        action: click(event),
        successMessage: this.successMessage,
      });
    },
  },
};
</script>

<docs>
  A button that includes a feedback message when the `click` operation is successful or not. The `click` handler must return a Promise.

  A simple feeback button:
  ```vue
  <template>
    <div>
      <feedback-button @click="saveOk">Save (successful)</feedback-button>
      <feedback-button @click="saveError">Save (failure)</feedback-button>
    </div>
  </template>
  <script>
  export default {
    methods: {
      async saveOk() {
      },
      async saveError() {
        throw new Error('Some error happenend while saving.');
      }
    }
  }
  </script>
  ```

  It is also possible to provide a custom message. In case of error, the error itself is used as a message:

  ```vue
  <template>
    <div>
      <feedback-button @click="saveOk" success-message="Team was updated successfully.">Save (successful)</feedback-button>
      <feedback-button @click="saveError">Save (failure)</feedback-button>
    </div>
  </template>
  <script>
  export default {
    methods: {
      async saveOk() {
      },
      async saveError() {
        throw "The team your are editing does not exist in our database.";
      }
    }
  }
  </script>
  ```
</docs>
