<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    :width="size"
    :height="size"
    viewBox="0 0 24 24"
    role="presentation"
    :class="isStroked ? $style['stroked'] : $style['filled']"
  >
    <title v-if="title" id="icon">{{ title }}</title>
    <component :is="currentIcon"></component>
  </svg>
</template>

<script>
import { capitalizeFirstLetter, reloadOnMissingChunks } from "@/utils";
import camelCase from "lodash.camelcase";

export default {
  props: {
    /**
     * Name of the svg icon to be rendered.
     * Corresponding svg icon component will be dynamically imported.
     */
    icon: {
      type: String,
      required: true,
    },
    /**
     * Size of the svg icon.
     */
    size: {
      type: [Number, String],
      default: 20,
    },
    isStroked: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: "",
    },
  },
  computed: {
    /**
     * Icon component is dynamically imported based
     * on icon property and rendered.
     */
    currentIcon() {
      const iconFile = capitalizeFirstLetter(camelCase(this.icon)) + "Icon.vue";
      return () =>
        import(`@/components/icons/svg/${iconFile}`).catch(
          reloadOnMissingChunks
        );
    },
  },
};
</script>

<style lang="scss" module>
svg {
  vertical-align: top;

  &.stroked {
    fill: none;
    stroke: currentColor;
  }

  &.filled {
    fill: currentColor;
  }
}
</style>

<docs>
  This is an adapter for svg icon components defined in `./icons/svg/` directory.

  These icons must be named in a format of `<prefix>Icon.vue` where `<prefix>` defines a possible string value
  for `icon` property of `BaseIcon` adapter.

  For example naming your svg icon component `SuccessIcon.vue` results into following usage with this adapter:
  ```jsx
  <base-icon icon="success"/>
  ```
  ## Examples
  List of all icons which are currently supported (have their corresponding svg icon component in `./icons/svg/`).

  ```jsx
  <base-icon icon="multi"/>
  ```

  ```jsx
  <base-icon icon="edit"/>
  ```

  ```jsx
  <base-icon icon="info"/>
  ```

  ```jsx
  <base-icon icon="warning"/>
  ```

  ```jsx
  <base-icon icon="error"/>
  ```

  ```jsx
  <base-icon icon="success"/>
  ```

  ```jsx
  <base-icon icon="delete"/>
  ```

  ```jsx
  <base-icon icon="add"/>
  ```

  ```jsx
  <base-icon icon="team"/>
  ```

  ```jsx
  <base-icon icon="program"/>
  ```

  ```jsx
  <base-icon icon="solution"/>
  ```

  ```jsx
  <base-icon icon="shield"/>
  ```

  ```jsx
  <base-icon is-stroked  icon="units"/>
  ```

  ```jsx
  <base-icon icon="trash"/>
  ```

  ```jsx
  <base-icon icon="enabled"/>
  ```

  ```jsx
  <base-icon icon="disabled"/>
  ```

  ```jsx
  <base-icon icon="loading"/>
  ```

  ```jsx
  <base-icon icon="planning"/>
  ```

  ```jsx
  <base-icon is-stroked icon="integration"/>
  ```

  ```jsx
  <base-icon is-stroked icon="users" />
  ```

  ```jsx
  <base-icon is-stroked icon="copy" />
  ```
</docs>
