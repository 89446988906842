<template>
  <div />
</template>

<script>
import { logoutAndRedirect } from "@/utils";

export default {
  mounted() {
    logoutAndRedirect(this.$store, this.$router);
  },
};
</script>
