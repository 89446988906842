import VueFormGenerator from "vue-form-generator";
import VueUploadComponent from "vue-upload-component";

import CommonData from "./common-data.js";
import AlmHandler from "./handlers/almHandler";
import { capitalize, formatCurrency, formatDate } from "./utils/filters";
import VueGtag from "vue-gtag";
import { gaKey, isCloud } from "@/environment";
import { autofocus } from "@/directives/autofocus";
import { focusTrap } from "@/directives/focus-trap";
import { keyboardNavigation } from "@/directives/keyboardNavigation";
import FieldCheckBox from "./pages/registerCustomFields/fieldCheckBox.vue";

export function initializeGlobalVue(vue) {
  vue.use(VueFormGenerator);
  vue.use(CommonData);
  vue.use(AlmHandler);
  vue.use(VueGtag, {
    disableScriptLoad: !isCloud,
    bootstrap: false,
    config: { id: gaKey },
    enabled: !!gaKey,
  });

  vue.directive("autofocus", autofocus());
  vue.directive("focus-trap", focusTrap());
  vue.directive("keyboard-nav", keyboardNavigation());

  vue.component("FileUpload", VueUploadComponent);
  vue.component("FieldCheckBox", FieldCheckBox);

  vue.filter("date", formatDate);
  vue.filter("currency", formatCurrency);
  vue.filter("capitalize", capitalize);
}
