import { webappUrl } from "@/environment";

export function appBaseUrl(company) {
  return company ? `${webappUrl}?company=${company}` : webappUrl;
}

export function appUrl(path) {
  return `${webappUrl}#${path}`;
}

export function appBoardUrl(sessionId, boardType) {
  return `${webappUrl}#/app/${sessionId}/*/${boardType}`;
}

export function appDemoSessionWelcomeUrl() {
  return `${webappUrl}#/app/PI Planning to play around/FulfillmentTeam/flex/Welcome`;
}
