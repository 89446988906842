import { createApiClient } from "@/services/apiClient";

const root = "v1/companies";
const endpoints = {
  settings: (companyID) => `${root}/${companyID}/settings`,
  license: (companyID) => `${root}/${companyID}/license`,
  theme: (companyID) => `${root}/${companyID}/theme`,
  passwordRules: (companyID) => `${root}/${companyID}/password-rules`,
};

export class CompanyService {
  constructor(authAPIUrl, companyID) {
    this.apiClient = createApiClient(authAPIUrl);
    this.companyID = companyID;
  }

  async getLicense() {
    const path = endpoints.license(this.companyID);
    return this.apiClient.get(path).then(formatReponse);
  }

  async getPasswordRules() {
    const path = endpoints.passwordRules(this.companyID);
    return this.apiClient.get(path).then(formatReponse);
  }

  async getTheme() {
    const path = endpoints.theme(this.companyID);
    return this.apiClient.get(path).then(formatReponse);
  }

  async setTheme(theme_name) {
    const path = endpoints.theme(this.companyID);
    return this.apiClient.patch(path, { name: theme_name }).then(formatReponse);
  }

  async getSettings() {
    const path = endpoints.settings(this.companyID);
    const { data } = await this.apiClient.get(path);

    return {
      data: { autoLogoutAfter: data?.data?.auto_logout_after },
      success: data?.data?.status === "success",
    };
  }

  async setSettings(settings) {
    const path = endpoints.settings(this.companyID);
    return this.apiClient.post(path, settings).then(formatReponse);
  }
}

const formatReponse = (res) => {
  return {
    success: res.data.status === "success",
    data: res.data.data,
  };
};
