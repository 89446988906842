var render = function render(){var _vm=this,_c=_vm._self._c;return ((_vm.type)==='checkbox')?_c('input',_vm._g({directives:[{name:"model",rawName:"v-model",value:(_vm.inputValue),expression:"inputValue"}],ref:"input",class:{
    [_vm.$style.input]: true,
    [_vm.$style.empty]: !_vm.inputValue,
    [_vm.$style['with-errors']]: _vm.showErrors,
  },attrs:{"id":_vm.id,"type":"checkbox"},domProps:{"checked":Array.isArray(_vm.inputValue)?_vm._i(_vm.inputValue,null)>-1:(_vm.inputValue)},on:{"invalid":function($event){return _vm.$emit('invalid', $event)},"change":function($event){var $$a=_vm.inputValue,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=null,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.inputValue=$$a.concat([$$v]))}else{$$i>-1&&(_vm.inputValue=$$a.slice(0,$$i).concat($$a.slice($$i+1)))}}else{_vm.inputValue=$$c}}}},_vm.listeners)):((_vm.type)==='radio')?_c('input',_vm._g({directives:[{name:"model",rawName:"v-model",value:(_vm.inputValue),expression:"inputValue"}],ref:"input",class:{
    [_vm.$style.input]: true,
    [_vm.$style.empty]: !_vm.inputValue,
    [_vm.$style['with-errors']]: _vm.showErrors,
  },attrs:{"id":_vm.id,"type":"radio"},domProps:{"checked":_vm._q(_vm.inputValue,null)},on:{"invalid":function($event){return _vm.$emit('invalid', $event)},"change":function($event){_vm.inputValue=null}}},_vm.listeners)):_c('input',_vm._g({directives:[{name:"model",rawName:"v-model",value:(_vm.inputValue),expression:"inputValue"}],ref:"input",class:{
    [_vm.$style.input]: true,
    [_vm.$style.empty]: !_vm.inputValue,
    [_vm.$style['with-errors']]: _vm.showErrors,
  },attrs:{"id":_vm.id,"type":_vm.type},domProps:{"value":(_vm.inputValue)},on:{"invalid":function($event){return _vm.$emit('invalid', $event)},"input":function($event){if($event.target.composing)return;_vm.inputValue=$event.target.value}}},_vm.listeners))
}
var staticRenderFns = []

export { render, staticRenderFns }