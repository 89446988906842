<template>
  <div class="hover-box" :aria-labelledby="`content_${_uid}`" tabindex="0">
    <span aria-hidden="true">?</span>
    <div class="hover" :class="{ left }">
      <div :id="`content_${_uid}`" class="content">
        <slot></slot>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    left: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/_variables-legacy.scss";

.hover-box {
  position: relative;
  display: inline-block;
  background: $darkBlueColor;
  color: $whiteColor;
  font-size: 1rem;
  margin-left: 5px;
  width: 1.5rem;
  height: 1.5rem;
  line-height: 1.5rem;
  text-align: center;
  cursor: pointer;

  span {
    color: white;
    font-size: 1rem;
  }

  .hover {
    display: none;
    position: absolute;
    top: -20px;
    height: 70px;
    width: 50px;

    &.left {
      left: -30px;

      .content {
        left: auto;
        right: 30px;

        &:before {
          left: auto;
          right: -20px;
        }
      }
    }

    .content {
      display: none;
      position: absolute;
      left: 35px;
      top: 5px;
      width: 244px;
      box-sizing: border-box;
      padding: 9px;
      color: $blackColor;
      border: 4px solid $darkBlueColor;
      height: auto;
      line-height: 1.15;
      background-color: $whiteColor;
      z-index: 10;

      &:before {
        position: absolute;
        content: "";
        left: -20px;
        top: 20px;
        width: 20px;
        height: 4px;
        background: $darkBlueColor;
      }

      p {
        padding-bottom: 10px;
      }
    }
  }

  &:hover .hover,
  &:focus .hover {
    display: block;
  }

  &:hover,
  &:focus,
  .hover:hover,
  .hover:focus {
    .content {
      display: block;
    }
  }
}
</style>

<docs>
Hover box to inform user about something on the page. Text is passed through a slot.

### Examples

```jsx
<hover-box>
  Description which is helpful to the user.
  This could potentially be a really long message.
</hover-box>
```
</docs>
