<template>
  <div :class="$style['avatar']">
    <slot>
      <span v-if="text" aria-hidden="true">{{ text }}</span>
    </slot>
  </div>
</template>

<script>
export default {
  props: {
    text: {
      type: String,
      default: "",
      validator: (value) => {
        return value.length <= 2;
      },
    },
  },
};
</script>

<style lang="scss" module>
@import "@/_variables-legacy.scss";

.avatar {
  display: inline-flex;

  img,
  span {
    border-radius: 50%;
    height: 47px;
    width: 47px;
  }

  &:global(.large) {
    img,
    span {
      height: 90px;
      width: 90px;
    }
  }

  span {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    font-size: $font-semi-medium;
    background-color: $mediumOrangeColor;
  }
}
</style>

<docs>
  Avatar is used to represent a person (e.g. logged in user).
  Image is passed through `slot`. Text can be passed either through slot or as a `text` prop.

  ### Examples
  With image:
  ```jsx
  <avatar><img src="https://images.unsplash.com/photo-1535713875002-d1d0cf377fde"></avatar>
  ```

  With text:
  ```jsx
  <avatar text="RE"></avatar>
  ```
</docs>
