import { getWeekStartByLocale } from "weekstart";
import { userLocale } from "@/utils";

function createDateFormatter(timeZone) {
  return window.Intl && window.Intl.DateTimeFormat
    ? new Intl.DateTimeFormat(userLocale(), {
        day: "2-digit",
        month: "2-digit",
        year: "numeric",
        timeZone,
      })
    : {
        format: (val) =>
          `${val.getFullYear()}/${pad(val.getMonth() + 1)}/${pad(
            val.getDate()
          )}`,
      };
}

const numberFormatter =
  window.Intl && window.Intl.NumberFormat
    ? new Intl.NumberFormat(userLocale())
    : { format: (val) => val };

function pad(s) {
  return s < 10 ? "0" + s : s;
}

export function formatCurrency(value) {
  return "$" + numberFormatter.format(Math.round(value || 0));
}

export function formatDate(value) {
  // Returns the date passed in value (in UTC) in the user's local timezone.
  // E.g. if a Date 2022-01-01 00:01:00 is passed and the user lives in the America/Los_Angeles timezone (UTC-8),
  // the returned value is 2022-01-00 (day before the one specified in UTC)
  var localTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  console.log("LOCAL TIMEZONE", localTimezone, value);
  return createDateFormatter(localTimezone).format(value);
}

export function formatDateUTC(value) {
  // Returns the date passed (UTC) in UTC.
  // E.g. if the backend returns a unix timestamp which represents 2022-01-01 00:01:00 in UTC,
  // everyone reading the returned value will see 2022-01-01, no matter where they are.
  return createDateFormatter("UTC").format(value);
}

/**
 * Format the given Date as a string 'YYYY-MM-DD' for input fields
 */
export function formatDateForInput(value) {
  return value?.toISOString?.().slice(0, 10);
}

export function weekStartsOnMonday() {
  return getWeekStartByLocale(userLocale()) === 1;
}

export function capitalize(value) {
  if (!value) return "";
  value = value.toString();
  return value.charAt(0).toUpperCase() + value.slice(1);
}
