<template>
  <base-button :class="$style['edit-button']" v-on="$listeners">
    <base-icon icon="edit" />
  </base-button>
</template>

<script>
import BaseIcon from "@/components/icons/BaseIcon.vue";
import BaseButton from "@/components/BaseButton.vue";

export default {
  components: {
    BaseIcon,
    BaseButton,
  },
};
</script>

<docs>
  ### Examples

  Edit button:
  ```jsx
  <edit-button></edit-button>
  ```

  Disabled edit button:
  ```jsx
  <edit-button disabled></edit-button>
  ```
</docs>

<style lang="scss" module>
.edit-button {
  padding: 0;
  margin: 0;
  min-width: 35px;
  height: 30px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}
</style>
