<template>
  <div :id="id" class="select-list">
    <!-- Results count (screen reader only) -->
    <div v-if="items.length > 0" class="screen-reader-only" aria-live="polite">
      Showing {{ items.length }} Results
    </div>

    <!-- No-results message -->
    <div
      v-if="items.length === 0"
      :id="id + '0'"
      class="item disabled"
      aria-live="polite"
    >
      {{ noItemsMessage }}
    </div>

    <!-- Results -->
    <!-- eslint-disable-next-line vuejs-accessibility/no-static-element-interactions vuejs-accessibility/click-events-have-key-events -->
    <div
      v-for="(item, index) in items"
      v-else
      :id="id + index"
      :key="item.id"
      class="item"
      :role="itemRole"
      :class="{ current: index === currentIndex, disabled: disabled(item) }"
      @pointermove="currentIndex = index"
      @pointerdown.prevent
      @click.prevent="select(index)"
    >
      <slot :item="item">{{ item }}</slot>
    </div>
  </div>
</template>

<script>
export default {
  name: "SelectList",
  props: {
    id: {
      type: String,
      required: true,
    },
    items: {
      type: Array,
      required: true,
    },
    noItemsMessage: {
      type: String,
      default: "",
    },
    selected: {
      required: false,
      default: null,
    },
    disabled: {
      type: Function,
      default: () => false,
    },
    noInitialCurrent: {
      // If true, allows a state where no item is selected
      type: Boolean,
      default: false,
    },
    itemRole: {
      type: String,
    },
  },
  data() {
    return {
      currentIndex: this.selectedIndex(),
    };
  },
  watch: {
    currentIndex() {
      this.scrollCurrentIntoView();
    },
    items() {
      if (!this.noInitialCurrent) {
        this.moveCurrent(0);
        this.scrollCurrentIntoView();
      } else {
        this.currentIndex = -1;
      }
    },
  },
  methods: {
    scrollCurrentIntoView() {
      if (this.currentIndex >= 0) {
        const itemElem = document.getElementById(this.id + this.currentIndex);
        itemElem.scrollIntoView({ block: "nearest", inline: "nearest" });
      }
    },
    selectedIndex() {
      return this.items.findIndex((i) => i === this.selected);
    },
    moveCurrent(delta) {
      if (this.items.length > 0) {
        if (this.currentIndex < 0) {
          this.currentIndex = 0;
        } else {
          this.currentIndex = Math.min(
            this.items.length - 1,
            Math.max(0, this.currentIndex + delta)
          );
        }
      }
      this.$emit("current-id-set", `${this.id}${this.currentIndex}`);
    },
    select(index) {
      const item = this.items[index ?? this.currentIndex];
      if (!this.disabled(item)) {
        this.$emit("select", item);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/_variables-legacy.scss";
@import "@/styles/accessibility.scss";

.select-list {
  width: 100%;
  box-sizing: border-box;
  border-top: 3px solid $whiteColor;
  border-bottom: 3px solid $darkBlueColor;
  max-height: 300px;
  overflow: auto;

  .item {
    display: flex;
    flex-direction: column;
    justify-content: center;
    background-color: $lightGreyColor;
    border-bottom: 3px solid $whiteColor;
    cursor: pointer;
    height: 60px;
    padding-left: 50px;

    &.disabled {
      pointer-events: none;
      color: $greyColor;
    }

    &.current:not(.disabled) {
      background-color: $darkBlueColor;
      color: $whiteColor;
    }
  }
}
</style>
<docs>
Shows a list of item which can be selected. The `disabled` function prop can be used to make an item not selectable.

### Examples
```vue
<template>
  <select-list id="person-list" :items="persons" :disabled="p=>p.id===3" @select="selected" v-slot="{item}">
    {{ item.name }}
  </select-list>
</template>
<script>
export default {
  data() {
    return {
      persons: [{ id: 1, name: "Anna" }, { id: 2, name: "John" }, { id: 3, name: "House" }]
    };
  },
  methods: {
    selected(item) {
      alert(`${item.name} selected.`);
    }
  }
};
</script>
```


</docs>
