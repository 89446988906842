import { render, staticRenderFns } from "./UnauthorizedPage.vue?vue&type=template&id=0e6b9ea6&scoped=true&"
var script = {}
import style0 from "./UnauthorizedPage.vue?vue&type=style&index=0&id=0e6b9ea6&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0e6b9ea6",
  null
  
)

export default component.exports