import { setTakenSprintIDs } from "@/handlers/almHandler";

export function almHandlerCommon(remote, connectionID, almSessionId) {
  return {
    isAlm: !!connectionID,
    delete: () => {
      return remote.call("delete", [], { connection_id: connectionID });
    },
    canConnect: () => {
      return remote.call("can_connect", [], {
        connection_id: connectionID,
      });
    },
    getName: () => {
      return remote.call("get_name", [], {
        connection_id: connectionID,
      });
    },
    setName: (name) => {
      return remote.call("set_name", [], {
        name,
        connection_id: connectionID,
      });
    },
    getUrl: () => {
      return remote.call("get_url", [], {
        connection_id: connectionID,
      });
    },
    setUrl: (url) => {
      return remote.call("set_url", [], {
        url,
        connection_id: connectionID,
      });
    },
    getUsername: () => {
      return remote.call("get_username", [], {
        connection_id: connectionID,
      });
    },
    setUsername: (username) => {
      return remote.call("set_username", [], {
        username,
        connection_id: connectionID,
      });
    },
    getApiKey: () => {
      return remote.call("get_apikey", [], {
        connection_id: connectionID,
      });
    },
    setApiKey: (apiKey) => {
      return remote.call("set_apikey", [], {
        apikey: apiKey,
        connection_id: connectionID,
      });
    },
    getTimeout: () => {
      return remote.call("get_timeout", [], {
        connection_id: connectionID,
      });
    },
    setTimeout: (timeout) => {
      return remote.call("set_timeout", [], {
        timeout,
        connection_id: connectionID,
      });
    },
    getIgnoreWebhooksFiredByAlmUser: () => {
      return remote.call("get_ignore_webhooks_fired_by_alm_user", [], {
        connection_id: connectionID,
      });
    },
    setIgnoreWebhooksFiredByAlmUser: (ignore) => {
      return remote.call("set_ignore_webhooks_fired_by_alm_user", [], {
        connection_id: connectionID,
        ignore_webhooks_fired_by_alm_user: ignore,
      });
    },
    getTlsVerification: () => {
      return remote.call("get_tls_verification", [], {
        connection_id: connectionID,
      });
    },
    setTlsVerification: (verify) => {
      return remote.call("set_tls_verification", [], {
        do_verify: verify,
        connection_id: connectionID,
      });
    },
    getCertificateName: (type) => {
      return remote.call("get_certificate_name", [], {
        type,
        connection_id: connectionID,
      });
    },
    setCertificate: (data, name, type) => {
      return remote.call("set_certificate", [], {
        data,
        name,
        type,
        connection_id: connectionID,
      });
    },
    deleteCertificate: (type) => {
      return remote.call("delete_certificate", [], {
        type,
        connection_id: connectionID,
      });
    },
    setPassphrase: (passphrase) => {
      return remote.call("set_passphrase", [], {
        passphrase,
        connection_id: connectionID,
      });
    },
    startSession: () => {
      return remote.call("session.start", [almSessionId]);
    },
    forceRunningSession: () => {
      return remote.call("session.force_running", [almSessionId]);
    },
    stopSession: () => {
      return remote.call("session.stop", [almSessionId]);
    },
    restartSession: () => {
      return remote.call("session.restart", [almSessionId]);
    },
    getStatus: () => {
      return remote.call("session.get_status", [], {
        session_id: almSessionId,
      });
    },
    setAlmType: (almType, artIds, boardType) => {
      return remote.call(
        "session.set_alm_type",
        [almSessionId, almType, artIds],
        { origin_board_type: boardType, connection_id: connectionID }
      );
    },
    getSessionMapping: () => {
      return remote.call("session.get_mapping", [almSessionId]);
    },
    setSessionMapping: (mapping) => {
      return remote.call("session.set_mapping", [almSessionId, mapping]);
    },
    async getTeamProjects(teamIds) {
      const mapping = await this.getMapping();
      let project = {};
      teamIds.forEach((teamId) => {
        let m = mapping.find((m) => m.user_id === teamId);
        if (m) {
          m.sources.forEach((s) => (project[s.project_id] = s));
        }
      });
      const projects = [];
      for (let id in project) {
        projects.push({ id: +id, name: project[id].name });
      }
      return projects;
    },
    async getTeamsWithProjects(selectedTeams, teamsAndProjects, isCreating) {
      const fetchAvailableSprints = (project) =>
        this.loadSourceSprints(project, isCreating);
      const selectedTeamIds = selectedTeams.map(
        (team) => `${team.id || team.user_id}`
      );
      const teamToProjectsMap = teamsAndProjects
        .filter(({ user_id: teamId }) => selectedTeamIds.includes(`${teamId}`))

        .reduce(teamToProjectsWithSprintMap(fetchAvailableSprints), {});
      let teamsWithProjects = await Promise.all(
        selectedTeams.map(async (team) => {
          const projects = (await teamToProjectsMap[team.id]) ?? [];
          const takenSprintIDs = Object.fromEntries(
            projects.map(({ id }) => [id, []])
          );

          return {
            name: team.name,
            id: team.id,
            sources: projects,
            takenSprintIDs,
          };
        })
      );
      teamsWithProjects.sort((a, b) => parseFloat(a.id) - parseFloat(b.id));
      return teamsWithProjects;
    },
    async addTeamsToIterations(iterations, teams, isCreating) {
      const teamToAlmProjectMap = await this.getMapping();
      const iterationsWithTeamsAndSprints = await this.getSessionMapping();

      // We take teams from the first iterations because they're the same in all iterations
      const teamIdsInIterations = iterationsWithTeamsAndSprints[0].teams.map(
        ({ team_id }) => `${team_id}`
      );
      const iterationToTeamSprintsMap = iterationsWithTeamsAndSprints.reduce(
        iterationsToTeamSprintsMap,
        {}
      );
      const teamsInAlm = teams.filter((team) =>
        teamIdsInIterations.includes(`${team.id}`)
      );

      const teamsWithProjects = await this.getTeamsWithProjects(
        teamsInAlm,
        teamToAlmProjectMap,
        isCreating
      );

      iterations.forEach((iteration) => {
        const teamToSprintMap = iterationToTeamSprintsMap[iteration.id];
        iteration.teams = JSON.parse(JSON.stringify(teamsWithProjects));
        iteration.teams.forEach((teamWithProjects) => {
          const projectToSprintMap = teamToSprintMap[teamWithProjects.id];
          teamWithProjects.sources.forEach((project) => {
            const sessionSource = projectToSprintMap[project.id] ?? {};
            this.setSourceSprint(project, sessionSource);
          });
        });
      });

      // prepare available sprints options without already selected sprints, i.e. set taken sprints IDs array
      iterations.forEach((iteration) => {
        iteration.teams.forEach((team) => {
          team.sources.forEach((source) =>
            setTakenSprintIDs(iterations, team.id, source.id)
          );
        });
      });
    },
    getTeamMappingSources(teamId) {
      return remote.call("get_team_mapping_sources", [connectionID, +teamId]);
    },
    setTeamMappingSources(teamId, sources) {
      return remote.call("set_team_mapping_sources", [
        connectionID,
        +teamId,
        sources,
      ]);
    },
    getArtMappingSources(artId) {
      return remote.call("get_art_mapping_sources", [connectionID, +artId]);
    },
    // TODO should probably be a backend function
    getArtsMappingSources(artIds) {
      const fetchSources = async (artId) => {
        const { data: sources } = await this.getArtMappingSources(artId);
        return {
          artId,
          projects: sources.map(({ project_id, name }) => ({
            id: project_id,
            name,
          })),
        };
      };
      return Promise.all(artIds.map(fetchSources));
    },
    setArtMappingSources(artId, sources) {
      return remote.call("set_art_mapping_sources", [
        connectionID,
        +artId,
        sources,
      ]);
    },
    checkIssueType() {
      return { success: true, data: [] };
    },
    getReleases() {
      return [];
    },
    async loadAlmItemTypes() {
      return await remote.call("item.load_alm_item_types", [connectionID]);
    },
    async searchAlmUsers(query) {
      return (
        await remote.call("session.search_alm_users", [connectionID, query])
      ).map(mapUserMapping);
    },
    async getAlmUser() {
      return mapUserMapping(
        await remote.call("session.get_alm_user", [connectionID])
      );
    },
    setAlmUser(almId) {
      return remote.call("session.set_alm_user", [connectionID, almId]);
    },
  };
}

function mapUserMapping(mapping) {
  return {
    almId: mapping?.alm_id,
    almName: mapping?.alm_name,
    pipId: mapping?.pip_id,
    pipName: "",
    error: mapping?.error,
  };
}

function projectToSprintMap(map, { id, ...sprint }) {
  return { ...map, [id]: sprint };
}

function teamToSprintsMap(map, { team_id, sources: sprints }) {
  return { ...map, [team_id]: sprints.reduce(projectToSprintMap, {}) };
}

function iterationsToTeamSprintsMap(map, { pi_iteration_id, teams }) {
  return {
    ...map,
    [pi_iteration_id]: teams.reduce(teamToSprintsMap, {}),
  };
}

async function addSprintsToProjects(fetchAvailableSprints, projects) {
  return await Promise.all(
    projects.map(async (project) => {
      const projectWithSprints = { ...project, sprint: null, sprints: [] };
      try {
        await fetchAvailableSprints(projectWithSprints);
      } catch (error) {
        console.error("Problem loading iterations", projectWithSprints, error);
        throw error;
      }
      return projectWithSprints;
    })
  );
}

function teamToProjectsWithSprintMap(fetchAvailableSprints) {
  return function teamToProjectsWithSprintMapInner(
    map,
    { user_id, sources: projects }
  ) {
    const projectsWithSprintsPromise = addSprintsToProjects(
      fetchAvailableSprints,
      projects
    );

    return { ...map, [user_id]: projectsWithSprintsPromise };
  };
}
