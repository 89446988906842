import { authAPIUrl } from "@/environment";
import { LicenseService } from "@/services/license.service";
import { RemoteCaller } from "./remoteCaller";

export class AdminHandler {
  constructor(context, apiVersion = 2, isUsingRest = false) {
    this.remote = new RemoteCaller(context, "auth", apiVersion);
    this.company = context.company;
    this.licenseService = new LicenseService(authAPIUrl);
    this.isUsingRest = isUsingRest;
  }

  getLicense() {
    if (this.isUsingRest) {
      return this.licenseService.getOnPremKey();
    }
    return this.remote.call("get_license");
  }

  setLicense(license) {
    if (this.isUsingRest) {
      return this.licenseService.updateOnPremKey(license);
    }
    return this.remote.call("set_license", [license]);
  }
}
