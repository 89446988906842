export function sortStickies(stickies, origin) {
  let mirror = stickies.filter((s) => s.origin_board_type !== origin);
  mirror.sort((a, b) => a.name.localeCompare(b.name));
  let origins = stickies.filter((s) => s.origin_board_type === origin);
  origins.sort((a, b) => (a.index || 0) - (b.index || 0));
  return origins.concat(mirror);
}

export function arraySet(source, values, use) {
  let res = values.slice();
  for (let i = 0; i < source.length; i++) {
    if (use.indexOf(source[i]) < 0) {
      res.push(source[i]);
    }
  }
  return res;
}

export function findMirrorType(sticky, mirrorTypes, possible) {
  for (let i = 0; i < mirrorTypes.length; i++) {
    if (
      arrayEquals(sticky.available_board_types, mirrorTypes[i].boards, possible)
    ) {
      return mirrorTypes[i].name;
    }
  }
}

export function arrayEquals(firstArray, secondArray, use) {
  return (
    arrayContains(firstArray, secondArray, use) &&
    arrayContains(secondArray, firstArray, use)
  );
}

function arrayContains(firstArray, secondArray, use) {
  for (let i = 0; i < secondArray.length; i++) {
    if (
      use.indexOf(secondArray[i]) >= 0 &&
      firstArray.indexOf(secondArray[i]) < 0
    ) {
      return false;
    }
  }
  return true;
}

export function saveLocalSettings(settings) {
  if (typeof Storage !== "undefined") {
    let existingSettings = localStorage.getItem("settings");
    existingSettings = existingSettings ? JSON.parse(existingSettings) : {};

    for (const key in settings) {
      existingSettings[key] = settings[key];
    }

    localStorage.setItem("settings", JSON.stringify(existingSettings));
  }
}
