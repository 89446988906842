<template>
  <div class="footer flex flex-wrap space-between">
    <div class="links-wrap flex flex-wrap">
      <div class="footer-column">
        <a
          v-for="item in footerInfo1"
          :key="item.text"
          class="footer-link fs-semi-medium fc-white"
          :href="item.href"
          target="_blank"
          >{{ item.text }}</a
        >
      </div>
      <div class="footer-column">
        <a
          v-for="item in footerInfo2"
          :key="item.text"
          class="footer-link fs-semi-medium fc-white"
          :href="item.href"
          target="_blank"
          >{{ item.text }}</a
        >
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      footerInfo1: [
        {
          text: "Privacy Policy",
          href: "https://www.piplanning.io/legal/privacy-policy",
        },
        {
          text: "Terms of Service",
          href: "https://www.piplanning.io/legal/terms-of-service",
        },
      ],
      footerInfo2: [
        {
          text: "Documentation",
          href: "https://howto.piplanning.io",
        },
        {
          text: "Releases",
          href: "http://piplanning.io/releases",
        },
      ],
    };
  },
};
</script>

<style lang="scss" scoped>
@import "@/_variables-legacy.scss";

.footer {
  width: 100%;
  padding: 50px 40px 20px;
  box-sizing: border-box;
  background-color: $blackColor;

  .footer-column {
    width: 20%;
    min-width: 300px;
  }

  .footer-column .footer-link {
    display: block;
    margin-bottom: 20px;
  }
}
</style>
