import { v4 } from "uuid";

export const toastStoreConf = {
  namespaced: true,
  state: () => ({
    messages: [],
  }),
  mutations: {
    addMessage(state, message) {
      state.messages = [...state.messages, message];
    },
    removeMessage(state, messageToRemove) {
      state.messages = state.messages.filter(
        (message) => message !== messageToRemove
      );
    },
  },
  actions: {
    showMessage({ commit }, { message, type = "success" }) {
      if (!message) {
        return;
      }
      const messageObject = { message, type, id: v4() };
      commit("addMessage", messageObject);
      setTimeout(
        () => {
          commit("removeMessage", messageObject);
        },
        type === "success" ? 5000 : 10000
      );
    },
    async showFeedback(
      { dispatch },
      { action, successMessage = "Operation completed successfully." }
    ) {
      try {
        await action;
        dispatch("showMessage", { message: successMessage });
      } catch (error) {
        const message = error && error.message ? error.message : error;
        dispatch("showMessage", { message, type: "error" });
      }
    },
  },
};
