export function almHandlerAc(remote, connectionID, almSessionId) {
  function getTags() {
    return remote
      .call("get_tags", [connectionID])
      .then((res) =>
        res.tags.map((tag) => ({ id: tag.tag_id, name: tag.name }))
      );
  }

  return {
    name: "Rally",
    supportsLinks: false,
    checkStatus: () => {
      return Promise.resolve({ working: true });
    },
    getMapping: () => {
      return remote.call("get_project_mapping", [connectionID]);
    },
    setProjectMapping: (mapping) => {
      return remote.call("set_project_mapping", [connectionID, mapping]);
    },
    getProjects: () => {
      return remote.call("get_projects", [connectionID]).then((res) =>
        res.projects.map((project) => ({
          id: project.object_id,
          name: project.name,
        }))
      );
    },
    getIssueFields(ac_type, field_type) {
      return remote.call("get_fields", [connectionID, ac_type, field_type]);
    },
    getIssueTypes: () => {
      return remote
        .call("get_issue_types", [], { connection_id: connectionID })
        .then((res) =>
          res.ac_types.map((ac_type) => ({
            id: ac_type.id,
            ac_type: ac_type.element_name,
            name: ac_type.display_name,
          }))
        );
    },
    getPortfolioIssueTypes: () => {
      return remote
        .call("get_portfolio_workitem_types", [], {
          connection_id: connectionID,
        })
        .then((workItemTypes) =>
          workItemTypes
            .filter(({ ordinal }) => ordinal === 0)
            .map(({ type_path, display_name }) => ({
              id: type_path,
              ac_type: type_path,
              name: display_name,
            }))
        );
    },
    getReleases: async (projectId = -1, artIds = []) => {
      if (projectId === -1 && (!artIds || artIds.length === 0)) {
        return [];
      }
      if (projectId === -1) {
        projectId = null;
      }

      const releases = await remote.call("get_releases", [connectionID], {
        project_id: projectId,
        art_ids: artIds,
      });
      return releases.map((release) => ({
        id: release.ObjectID,
        name: release.Name,
      }));
    },
    getAlmTypes: () => {
      return remote
        .call("session.get_alm_types", [almSessionId])
        .then((almTypes) =>
          almTypes.map((almType) => ({
            id: almType.id,
            issue_type: almType.ac_type,
            project_id: almType.project_id,
            wsjf: (stepEstimate) =>
              stepEstimate && almType.ac_step_value_field_elementName
                ? almType.ac_step_value_field_elementName
                : almType.ac_wsjf_field_elementName,
            tags: almType.ac_tags || [],
            release: almType.ac_release_id || -1,
          }))
        );
    },
    getTeamBoardAlmType: (almType) => {
      return almType ? { ac_type: almType.issue_type } : {};
    },
    getBacklogBoardAlmType: (sticky, almType) => {
      return almType
        ? {
            ac_type: almType.issue_type,
            selectedField: almType.wsjf(sticky.step_estimate),
            tags: almType.tags,
            release: almType.release,
          }
        : {};
    },
    getTags,
    loadSourceSprints: (source, isCreating) => {
      if (!source.project_id) {
        return Promise.resolve();
      }
      return remote
        .call("get_iterations", [connectionID, source.project_id.toString()])
        .then((iterations) => {
          source.sprints = iterations
            .filter((iter) => !isCreating || iter.State !== "Accepted")
            .map((iter) => ({ id: iter.ObjectID, name: iter.Name }));
          source.sprints.unshift({
            name: "Don't sync to Rally",
            id: null,
          });
        });
    },
    setSourceSprint: async (source, sessionSource) => {
      source.sprint =
        (sessionSource.ac_iteration_id || sessionSource.sprint) ?? null;
    },
    createTestItem: ({ project_id, work_item_type_id, default_fields }) => {
      return remote.call("create_test_item", [
        connectionID,
        project_id,
        work_item_type_id,
        default_fields,
      ]);
    },
    getWebhooks: () => {
      return remote.call("get_webhooks", [connectionID]);
    },
    createWebhook: (name) => {
      return remote.call("create_webhook", [], {
        connection_id: connectionID,
        name,
      });
    },
    deleteWebhook: (ref) => {
      return remote.call("delete_webhook", [], {
        connection_id: connectionID,
        webhook_ref: ref,
      });
    },
    getWorkspaces: () => {
      return remote.call("get_workspaces", [], { connection_id: connectionID });
    },
    getWorkspace: () => {
      return remote.call("get_workspace", [], { connection_id: connectionID });
    },
    setWorkspace: (workspaceId) => {
      return remote.call("set_workspace", [connectionID, workspaceId]);
    },
  };
}
