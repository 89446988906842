import Cookies from "js-cookie";
import AuthService from "@/services/auth.service";

export function generateID() {
  return "xxxxxxxxxxxxxxxyxxxxxxxx".replace(/[xy]/g, function (c) {
    let r = (Math.random() * 16) | 0;
    let v = c === "x" ? r : (r & 0x3) | 0x8;
    return v.toString(16);
  });
}

export function colorOrFallback(color) {
  // use the following color as a fallback:
  // rgb(197, 244, 10) = [0.7725490196078432, 0.9568627450980393, 0.0392156862745098, 1]
  const fallbackColor = [
    0.7725490196078432, 0.9568627450980393, 0.0392156862745098, 1,
  ];
  return color || fallbackColor;
}

export function rgbToHex(r, g, b) {
  return "#" + componentToHex(r) + componentToHex(g) + componentToHex(b);
}

function componentToHex(c) {
  const hex = Math.round(c * 255).toString(16);
  return hex.length === 1 ? "0" + hex : hex;
}

const shortHexRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i;
const fullHexRegex = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i;

export function hexToRgba(hex) {
  if (!hex) {
    return hex;
  }
  const full = hex.replace(
    shortHexRegex,
    (m, r, g, b) => r + r + g + g + b + b
  );
  const result = fullHexRegex.exec(full);
  return result
    ? [
        hexToComponent(result[1]),
        hexToComponent(result[2]),
        hexToComponent(result[3]),
        1,
      ]
    : null;
}

function hexToComponent(h) {
  return parseInt(h, 16) / 255;
}

export function clearCookie() {
  Cookies.remove("cbtid");
}

export async function logout(store) {
  if (store.state.wsconnection.isOpen) {
    store.commit("closeConnection");
  }
  const res = await AuthService.logout();
  clearCookie();
  return res.data.redirectUrl;
}

export async function logoutAndRedirect(store, router) {
  let redirectUrl = await logout(store);
  if (redirectUrl) {
    window.location.assign(redirectUrl);
  } else {
    router.push({ name: "login" });
  }
}

export function setUnsavedChanges(hasChanged) {
  if (!this.isCreating) {
    this.$emit("change", hasChanged);
  }
}

export function traceErrors(contextName) {
  return async function traceError(promise) {
    try {
      return await promise;
    } catch (error) {
      console.error(`[${contextName}] Backend call failed:`, error);
      throw error;
    }
  };
}

export function showActionsMenu(event) {
  const { target } = event;

  const actionsRect = target.getBoundingClientRect();
  const dropdown = target.querySelector("ul");
  const dropdownRect = dropdown.getBoundingClientRect();

  const posX = actionsRect.x - dropdownRect.width + window.scrollX;
  const posY = actionsRect.y + window.scrollY;

  dropdown.style.top = posY + "px";
  dropdown.style.left = posX + "px";
}

export function reloadOnMissingChunks(error) {
  if (
    error.name === "ChunkLoadError" ||
    error.code === "CSS_CHUNK_LOAD_FAILED"
  ) {
    window.location.reload();
    return {};
  }
  console.error("Failed loading a component with an unknown error");
  throw error;
}

export function dateToUnixTimestamp(date) {
  return date ? Date.parse(date) / 1000 : null;
}

export function localDateToUnixTimestamp(date) {
  return date.getTime() / 1000 - date.getTimezoneOffset() * 60;
}

export function unixTimestampToDate(unixTimestamp) {
  return unixTimestamp ? new Date(unixTimestamp * 1000) : null;
}

export function userLocale() {
  return navigator.languages && navigator.languages.length
    ? navigator.languages[0]
    : navigator.language || "en";
}

export function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

/**
 * Downloads a file with the given filename using the provided data.
 *
 * @param {string} fileName - The desired filename for the downloaded file.
 * @param {ArrayBuffer|Blob|string} data - The file content.
 * @returns {void}
 */
export function downloadFile(fileName, data) {
  const blob = new Blob([data]);
  const url = window.URL.createObjectURL(blob);

  const a = document.createElement("a");
  a.style.display = "none";
  a.href = url;
  a.download = fileName;
  a.click();

  window.URL.revokeObjectURL(url);
  a.remove();
}
