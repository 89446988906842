<template>
  <base-input
    v-bind="$attrs"
    :value="value"
    type="number"
    @input="input"
    @keydown="down"
  />
</template>

<script>
import BaseInput from "@/components/BaseInput.vue";

export default {
  components: { BaseInput },
  props: {
    value: Number,
    maxlength: Number,
  },
  methods: {
    down(event) {
      if (
        event.key.length === 1 &&
        this.maxlength &&
        event.target.value.length === this.maxlength
      ) {
        event.preventDefault();
      }
    },
    input(value) {
      const val = parseInt(value);
      this.$emit("input", isNaN(val) ? null : val);
    },
  },
};
</script>
