var render = function render(){var _vm=this,_c=_vm._self._c;return _c('button',_vm._g({class:{
    [_vm.$style.button]: true,
    [_vm.$style.warning]: _vm.warning,
    [_vm.$style.validating]: _vm.validateForm && _vm.wasClicked,
    [_vm.$style.secondary]: _vm.secondary,
    [_vm.$style.tertiary]: _vm.tertiary,
  },attrs:{"disabled":_vm.isDisabled}},_vm.listeners),[(_vm.loading)?_c('base-icon',{class:_vm.$style['loading'],attrs:{"data-testid":"loading-icon","size":"24","icon":"loading"}}):_vm._e(),_vm._t("default")],2)
}
var staticRenderFns = []

export { render, staticRenderFns }