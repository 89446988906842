<template>
  <div class="choose-wrap" :class="{ valid }">
    <!-- eslint-disable-next-line vuejs-accessibility/interactive-supports-focus -->
    <div
      class="custom-select-wrap"
      :class="{ expand, disabled }"
      :tabindex="disabled ? -1 : 0"
      :aria-expanded="expand"
      role="button"
      @click="toggle"
      @keydown.enter="toggle"
      @keydown.space.prevent="toggle"
    >
      <div class="v-center selected-option selected" :class="{ disabled }">
        {{ text }}
      </div>
      <div class="expand-indicator"></div>
      <ul v-if="expand" class="choose-options" role="menu">
        <li
          v-for="(opt, index) in options"
          :key="index"
          class="option"
          tabindex="0"
          role="menuitem"
          :data-value="index"
        >
          {{ opt[valueProp] }}
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  name: "Choose",
  props: {
    value: {
      type: Object,
      required: true,
    },
    options: {
      type: Array,
      required: true,
    },
    valueProp: {
      type: String,
      required: true,
    },
    valid: Boolean,
    disabled: Boolean,
  },
  data() {
    return {
      expand: false,
      selected: false,
    };
  },
  computed: {
    text() {
      return this.value ? this.value[this.valueProp] : "";
    },
  },
  methods: {
    toggle(e) {
      if (this.disabled) {
        return;
      }
      this.expand = !this.expand;
      let index = +e.target.dataset.value;
      if (index >= 0) {
        this.$emit("input", this.options[index]);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/_variables-legacy.scss";

.custom-select-wrap.expand {
  height: auto;
  border-bottom-width: 0;
  overflow: visible;
}

.choose-options {
  position: absolute;
  width: 100%;
  background: $whiteColor;
  z-index: 1;

  li {
    height: 0;
    margin: 0;
    padding-top: 0;
    color: transparent;
    opacity: 0;
    transition: all 0.25s;
  }
}

.expand .choose-options {
  border-bottom: 3px solid $darkBlueColor;

  li {
    color: $blackColor;
    opacity: 1;
    height: 53px;
    margin: 4px 10px 3px;
  }
}
</style>
