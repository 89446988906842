import { createOldApiClient } from "@/services/apiClient";

import { authAPIUrl } from "@/environment";

const apiClient = createOldApiClient(authAPIUrl);

export default {
  register(params) {
    return apiClient.post("/auth/register", params, { timeout: 20000 });
  },
  canSetup() {
    //if something goes wrong, allow accessing setup page
    return apiClient
      .get("/onprem/setup")
      .then((res) => !res.data || res.data.success)
      .catch(() => true);
  },
  setup(params) {
    return apiClient.post("/onprem/setup", params, { timeout: 20000 });
  },
  isAuthenticated() {
    return apiClient.get("/auth/user?type=admin&path=safepiplannings");
  },
  logout() {
    return apiClient.get("/auth/logout?type=admin&path=safepiplannings");
  },
  refreshToken() {
    return apiClient.get("auth/token/refresh?type=admin&path=safepiplannings");
  },
  getServerInfo() {
    return apiClient.get("v1/server-info");
  },
};
