export function getFlexBoards() {
  return parseJSONLocalStorageItemIfExists("flexBoards", []);
}

export function setFlexBoards(boards) {
  return set("flexBoards", boards);
}

export function getLinks() {
  return parseJSONLocalStorageItemIfExists("links", []);
}

export function setLinks(links) {
  return set("links", links);
}

export function getSettings() {
  return parseJSONLocalStorageItemIfExists("settings", {});
}

export function setSettings(settings) {
  return set("settings", settings);
}

export function getGeneralInformation() {
  return parseJSONLocalStorageItemIfExists("generalInformation");
}

export function setGeneralInformation(generalInformation) {
  set("generalInformation", generalInformation);
}

export function getSelectedTeams() {
  return parseJSONLocalStorageItemIfExists("selectedTeams", []);
}

export function setSelectedTeams(teams) {
  set("selectedTeams", teams);
}

export function getSelectedParticipatingTeams() {
  return parseJSONLocalStorageItemIfExists("selectedParticipatingTeams");
}

export function setSelectedParticipatingTeams(selectedParticipatingTeams) {
  if (!selectedParticipatingTeams) {
    return;
  }
  set("selectedParticipatingTeams", selectedParticipatingTeams);
}

export function getTeamStickies(fallback) {
  return parseJSONLocalStorageItemIfExists("teamStickies", fallback);
}

export function setTeamStickies(stickies) {
  set("teamStickies", stickies);
}

export function getRiskStickies(fallback) {
  return parseJSONLocalStorageItemIfExists("riskStickies", fallback);
}

export function setRiskStickies(stickies) {
  set("riskStickies", stickies);
}

export function getProgramStickies(fallback) {
  return parseJSONLocalStorageItemIfExists("programStickies", fallback);
}

export function setProgramStickies(stickies) {
  set("programStickies", stickies);
}

export function getSolutionPlanningStickies(fallback) {
  return parseJSONLocalStorageItemIfExists("solutionStickies", fallback);
}

export function setSolutionPlanningStickies(stickies) {
  set("solutionStickies", stickies);
}

export function getBacklogStickies(fallback) {
  return parseJSONLocalStorageItemIfExists("backlogStickies", fallback);
}

export function setBacklogStickies(backlogStickies) {
  set("backlogStickies", backlogStickies);
}

export function getSolutionBacklogStickies(fallback) {
  return parseJSONLocalStorageItemIfExists(
    "solution_backlogStickies",
    fallback
  );
}

export function setSolutionBacklogStickies(solution_backlogStickies) {
  set("solution_backlogStickies", solution_backlogStickies);
}

export function getSelectedArtIds(fallback) {
  return parseJSONLocalStorageItemIfExists("selectedArts", fallback);
}

export function setSelectedArtIds(artIds) {
  set("selectedArts", artIds);
}

export function getSelectedSolutionTrainId() {
  return parseJSONLocalStorageItemIfExists("selectedSolutionTrain");
}

export function setSelectedSolutionTrainId(solutionTrainId) {
  set("selectedSolutionTrain", solutionTrainId);
}

export function getIterations() {
  return parseJSONLocalStorageItemIfExists("iterations");
}

export function setIterations(iterations) {
  set("iterations", iterations);
}

export function getTeamFieldMapping(fallback) {
  return parseJSONLocalStorageItemIfExists("teamFieldMapping", fallback);
}

export function setTeamFieldMapping(teamFieldMapping) {
  set("teamFieldMapping", teamFieldMapping);
}

export function clearLocalStorage() {
  remove([
    "generalInformation",
    "teamStickies",
    "riskStickies",
    "programStickies",
    "solutionStickies",
    "solution_backlogStickies",
    "backlogStickies",
    "iterations",
  ]);
}

export function resetBoards() {
  remove([
    "selectedArts",
    "selectedTeams",
    "teamStickies",
    "riskStickies",
    "programStickies",
    "solutionStickies",
    "solution_backlogStickies",
    "backlogStickies",
  ]);
}

function remove(keys) {
  keys.forEach((item) => localStorage.removeItem(item));
}

function parseJSONLocalStorageItemIfExists(key, fallback) {
  const item = localStorage.getItem(key);
  if (item && item !== "null") {
    return JSON.parse(item);
  }
  return fallback;
}

function set(key, value) {
  localStorage.setItem(key, JSON.stringify(value));
}
