import { ManagedError } from "@/errors";
import { addBreadcrumb, captureExceptionWithContext } from "@/sentry";
import store from "@/store";
import { RemoteError } from "@/handlers/RemoteError";

export class RemoteCaller {
  constructor(context, app, apiVersion, submodule = null) {
    this.context = context;
    this.app = app;
    this.apiVersion = apiVersion;
    this.room = context.company ? `.room.${context.company}` : "";
    this.session = context.session;
    this.submodule = submodule ? `.${submodule}` : "";
  }

  _getRemoteMethodName(method) {
    return `ch.rentouch.${this.app}.${this.apiVersion}${this.room}${this.submodule}.${method}`;
  }

  async _invoke(method, ...params) {
    try {
      /**
       * The breadcrumb promise should be awaited after the promise from the
       * session call, otherwise tests will break. This is because some code
       * paths do not await the promises, and adding one more promise on the
       * microtask queue will break the order of resolution - thus leading to
       * expected calls that were not done because not processed yet.
       */
      var breadcrumbPromise = breadcrumb(method, params[0], params[1]);
      const session = this.session();
      const res = await session.call(method, ...params);
      // TODO we should throw if success is false instead of the callers checking for success
      // feedback-button expects this behavior, so currently when success is false, the feedback-button shows a positive message
      // also apiClient throws when success if false
      // if (res?.success === false) {
      //   throw res.error;
      // }
      if (res?.success === false && res?.error?.code === "FORBIDDEN") {
        throw new RemoteError(res.error.message, res.error.code);
      }
      return res;
    } catch (error) {
      await captureExceptionWithContext(
        new Error(`Error calling ${method}`),
        this.callParameters(method, params[0], params[1], error)
      );
      throw instantiateError(error);
    } finally {
      try {
        await breadcrumbPromise;
      } catch (error) {
        console.error("Could not add the breadcrumb to Sentry:", error);
      }
    }
  }

  _progressiveInvoke(method, args, kwargs) {
    breadcrumb(method, args, kwargs);
    const session = this.session();
    return session
      .call(method, args, kwargs, {
        receive_progress: true,
      })
      .catch((error) => {
        captureExceptionWithContext(
          new Error(`Error calling ${method}`),
          this.callParameters(method, args, kwargs, error)
        );
        return Promise.reject(instantiateError(error));
      });
  }

  async call(methodName, ...params) {
    return this._invoke(this._getRemoteMethodName(methodName), ...params);
  }

  progressiveCall(methodName, args = [], kwargs = {}) {
    return this._progressiveInvoke(
      this._getRemoteMethodName(methodName),
      args,
      kwargs
    );
  }

  subscribe(methodName, callback) {
    return this.session().subscribe(
      this._getRemoteMethodName(methodName),
      callback
    );
  }

  callParameters(method, args, kwargs, error) {
    const positionalArguments = Object.fromEntries(
      (args || []).map((param, index) => [`Param ${index}`, param])
    );
    return {
      "Positional Arguments": positionalArguments,
      "Keyword Arguments": kwargs || {},
      Method: { name: method },
      Error: {
        ...error,
        message: error.message,
        args: error.args?.map(filterOauthParameters),
      },
      ...(error.code === "FORBIDDEN"
        ? { Permissions: this.context.user.permissions }
        : {}),
    };
  }
}

function breadcrumb(method, args, kwargs) {
  return addBreadcrumb("wamp call", {
    type: "http",
    data: {
      url: method.replace(".auth.", ".auserver."), // Sentry filters out auth
      args,
      kwargs,
    },
  });
}

/**
 * This is a helper function that will replace eventual OAuth parameters in an
 * URL. Some errors will return OAuth information including the tokens and it
 * is unsafe to log these sensitive bits of information.
 */
function filterOauthParameters(message) {
  return message.replace(/oauth_[^=]*=[^&\s]*/g, "[filtered_parameter]");
}

/**
 * This helper function will ensure that we get a proper Error instance.
 * Autobahn will raise an "error" which isn't a javascript Error instance, and
 * that does not play nicely with Sentry error reporting. This function will
 * ensure we always get a properly instantiated Error instance.
 */
function instantiateError(error) {
  if (error instanceof Error) {
    return error;
  }
  if (
    typeof error === "object" &&
    "error" in error &&
    "args" in error &&
    "kwargs" in error
  ) {
    return new Error(
      `${error.error}: ${error.args.map(filterOauthParameters).join(", ")}`
    );
  }
  return new Error(error);
}

export function ensureSuccessResponse(response) {
  if (response && Object.hasOwn(response, "success") && !response.success) {
    if (Object.hasOwn(response, "error")) {
      const errorMessage = filterOauthParameters(response.error);
      store.dispatch("toast/showMessage", {
        message: errorMessage,
        type: "error",
      });
      throw new ManagedError(`Error response from WAMP call: ${errorMessage}`);
    }
    throw new Error(`Unknown error response from WAMP call: ${response}`);
  }
}
