<template>
  <table
    :class="{
      [$style['data-table']]: true,
      [$style['sticky-head']]: isStickyHead,
    }"
  >
    <slot :align="alignOptions()"></slot>
  </table>
</template>

<script>
export default {
  props: {
    isStickyHead: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    alignOptions() {
      return {
        center: this.$style["text-center"],
        right: this.$style["text-right"],
      };
    },
  },
};
</script>

<style lang="scss" module>
@import "@/_variables-legacy.scss";

.data-table {
  width: 100%;
  text-align: left;
  font-size: 1.25rem;

  &.sticky-head {
    tr {
      &:first-child {
        th,
        td {
          background-color: $whiteColor;
          top: 0;
          position: sticky;
          z-index: 1;
        }
      }
    }
  }

  tr {
    height: 60px;

    td,
    th {
      border-bottom: 2px solid $darkBlueColor;
    }

    th:not([scope="row"]),
    :global(.th) {
      border-bottom: 3px solid $darkBlueColor;
    }

    td,
    th {
      padding: 16px 12px;

      &:first-child {
        padding-left: 50px;
      }

      &:last-child {
        padding-right: 50px;
      }

      &.text-right {
        text-align: right;
      }

      &.text-center {
        text-align: center;
      }
    }
  }
}
</style>

<docs>

The `<data-table>` component is used for displaying tabular data.
### Examples

#### Basic usage

You can use scoped slots to align specific columns `right` or `center` (`left` is default).
To make table head always stick to the top set a property `is-sticky-head` to `true`.

```vue
<template>
  <div class="my-scroll-table">
    <data-table :is-sticky-head="true">
      <template #default="{ align }">
        <tr>
          <th scope="col">Team</th>
          <th scope="col" :class="align.center">Edit</th>
        </tr>
        <tr v-for="team in teams" :key="team.id">
          <th scope="row">{{ team.name }}</th>
          <td :class="align.center"><edit-button /></td>
        </tr>
      </template>
    </data-table>
  </div>
</template>

<script>
import DataTable from "@/components/DataTable.vue";
import EditButton from "@/components/EditButton.vue";

export default {
  components: {  DataTable, EditButton },
  data() {
    return {
      teams: [
        { id: 1, name: "Unicorns", },
        { id: 2, name: "Penguins",  },
        { id: 3, name: "Lions", },
        { id: 4, name: "Dogs", },
        { id: 5, name: "Ducks", },
        { id: 6, name: "Cats" },
        { id: 7, name: "Antelopes", },
        { id: 8, name: "Frogs", },
      ],
    };
  }
};
</script>

<style lang="scss">
.my-scroll-table {
  /* height or max-height is important */
  height: 360px;
  overflow: hidden auto;
}
</style>
```
</docs>
