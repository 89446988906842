<template>
  <div class="billing-page relative main-section h-column-center">
    <h2 class="fs-extra-large">Billing</h2>

    <div>
      <div class="input-block">
        <p class="f-center fs-semi-medium" style="margin-bottom: 40px">
          To enhance your training with piplanning.io you can promote us to get
          it for free.
        </p>

        <div class="prices">
          <div class="box current">
            <span class="fs-medium">Free</span>
            <span>Extend for 3 months</span>
            <span>
              <div class="radio-element selected" />
            </span>
          </div>
        </div>
      </div>

      <div class="input-block" style="margin-top: 40px">
        <p class="fs-semi-medium">
          By writing a short post on LinkedIn (or similar) in which you mention
          how you have used our app in your training, you can get the piplanning
          app free for 3 months.
        </p>

        <p class="fs-semi-medium" style="margin-top: 20px">
          To get your free license, drop us a mail<br />
          - with the link to your post<br />
          - the name (pips-*) of your piplanning account<br />
          to:
          <a
            target="_blank"
            :href="`mailto:livesupport@piplanning.io?subject=Free License for ${company}&body=${mailBody}`"
          >
            livesupport@piplanning.io
          </a>
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { UserHandler } from "@/handlers/userHandler";
import back from "@/assets/back.png";
import check from "@/assets/license-icons/license.png";
import { isFeatureEnabled } from "@/feature";

export default {
  name: "BillingPage",
  data() {
    return {
      back,
      check,
      email: "",
      mailBody:
        "Hi there%0d%0dI wrote an article about piplanning.io, here is the link: ",
    };
  },
  computed: {
    ...mapGetters(["company", "session", "user"]),
    isRestApiEnabled() {
      return isFeatureEnabled(this.$route, "rest-api");
    },
    userHandler() {
      return new UserHandler(this.$store.getters, 3, this.isRestApiEnabled);
    },
  },
  async mounted() {
    this.email = this.user.email;
  },
};
</script>

<style lang="scss" scoped>
@import "@/_variables-legacy.scss";

.input-block {
  width: 100%;
  max-width: 600px;
  margin: auto;
}

.prices {
  display: flex;
  justify-content: center;

  .box {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 20px;
    height: 150px;
    width: 150px;
    border: 1px solid $greyColor;
    cursor: pointer;
    text-align: center;

    span {
      margin: 5px;
    }

    &.current {
      border: 3px solid $greenColor;
      margin: 18px;
    }
  }
}

.loading-indicator {
  display: inline-block;
}

.radio-element {
  box-sizing: border-box;
  width: 24px;
  height: 24px;
  border: 2px solid $darkBlueColor;
  border-radius: 50%;
  position: relative;
}

.radio-element.selected:before {
  content: "";
  position: absolute;
  left: 3px;
  top: 3px;
  width: 14px;
  height: 14px;
  border-radius: 50%;
  background: $darkBlueColor;
}
</style>
