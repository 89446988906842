import axios from "axios";
import { v4 as uuid } from "uuid";
import { captureMessageWithContext } from "@/sentry";

export function createApiClient(baseUrl, options) {
  const client = axios.create({
    baseURL: baseUrl,
    withCredentials: true,
    timeout: 10000,
    transformRequest: (data, headers) => {
      if (headers) {
        headers["common"]["correlation-id"] = uuid();
      }
      for (const transform of axios.defaults.transformRequest) {
        data = transform(data, headers);
      }
      return data;
    },
    ...options,
  });

  client.interceptors.response.use(
    (response) => {
      const data = response.data;
      if (
        data.success === false ||
        ("status" in data && data.status !== "success")
      ) {
        throw data.error;
      }
      return response;
    },
    (error) => {
      void captureMessageWithContext("unsuccessful request", {
        request: error.config.url,
        response: {
          data: error.response?.data,
          status: error.response?.status,
        },
      });

      if (error.response?.data?.status === "failed") {
        return {
          data: error.response?.data,
          code: error.code,
          config: error.config,
        };
      }

      if (error.response?.data?.error) {
        throw error.response.data.error;
      }
      throw "Could not execute this action";
    }
  );

  return client;
}

export function createOldApiClient(baseUrl, options) {
  return axios.create({
    baseURL: baseUrl,
    withCredentials: true,
    timeout: 10000,
    transformRequest: (data, headers) => {
      if (headers) {
        headers["common"]["correlation-id"] = uuid();
      }
      for (const transform of axios.defaults.transformRequest) {
        data = transform(data, headers);
      }
      return data;
    },
    ...options,
  });
}
