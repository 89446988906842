<template>
  <!-- eslint-disable-next-line vuejs-accessibility/click-events-have-key-events vuejs-accessibility/no-static-element-interactions -->
  <div
    v-if="showModal"
    v-focus-trap
    class="modal"
    role="dialog"
    aria-modal="true"
    aria-labelledby="delete-modal-legacy-title"
    @click.self="close()"
  >
    <div class="modal-content">
      <div class="modal-header">
        <button
          class="close closeModal"
          aria-label="Close"
          @click="close()"
          @keyup.enter="close()"
        >
          &times;
        </button>
      </div>
      <div class="modal-body">
        <h1
          id="delete-modal-legacy-title"
          ref="delete-title"
          v-autofocus
          tabindex="-1"
          class="fs-semi-extra-large"
        >
          {{ title }}
        </h1>
        <div class="popup-content">
          <slot></slot>
        </div>
        <div>
          <button
            class="next-btn red valid"
            :class="{ hold }"
            @click.prevent="doit()"
          >
            Delete
          </button>
        </div>
        <p class="server-error" role="alert">{{ error }}</p>
      </div>
    </div>
  </div>
</template>

<script>
import serverCaller from "./serverCaller";
import modal from "./modal";

export default {
  name: "DeleteModalLegacy",
  mixins: [serverCaller, modal],
  props: {
    title: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      showModal: false,
      hold: false,
      obj: null,
      error: "",
    };
  },
  methods: {
    show(obj) {
      this.showModal = true;
      this.hold = false;
      this.obj = obj;
      this.error = "";
      return obj;
    },
    doit() {
      this.hold = true;
      this.$emit("delete", this.obj);
    },
    fail(message) {
      this.hold = false;
      this.error = message;
    },
    close() {
      this.showModal = false;
    },
  },
};
</script>

<style lang="scss">
@import "@/styles/modal.scss";

.close {
  outline: revert;
}
</style>

<style lang="scss" scoped>
.modal {
  display: block;
}
</style>
