<template>
  <header :class="$style['header']">
    <div :class="$style['header-group']">
      <img :src="logo" alt="piplanning.io logo" />
      <span :class="$style['vertical-line']" />
      <h1 :class="[$style['header-title'], 'fs-semi-medium']">{{ title }}</h1>
    </div>

    <message-box
      v-if="notAvailable"
      :class="$style['error-message']"
      type="error"
    >
      {{ message }}
      <button :class="$style.link" @click="$router.go()">Refresh page</button>
    </message-box>

    <div :class="$style['header-group']">
      <button-anchor
        v-if="!onPrem"
        class="small"
        :href="webappUrl"
        target="_blank"
      >
        piplanning app &rarr;
      </button-anchor>

      <span :class="$style['vertical-line']" />

      <base-dropdown :class="$style['dropdown']" align="right">
        <template #trigger>
          <user-image
            v-if="user.email"
            :user="user"
            title="User"
            aria-label="User Menu"
          />
        </template>
        <div v-if="user.id" :class="$style['user-info']">
          <p v-if="user.name">{{ user.name }}</p>
          <p :class="$style['user-email']">{{ user.email }}</p>
        </div>

        <hr :class="$style['divider-line']" role="presentation" />

        <div :class="$style['dropdown-items-wrap']">
          <base-dropdown-item v-if="user.id">
            <sidebar-link
              :to="{ name: 'profile' }"
              :class="$style['dropdown-profile-item']"
            >
              <base-icon icon="profile" />
              Profile
            </sidebar-link>
          </base-dropdown-item>
          <base-dropdown-item>
            <sidebar-link
              :to="{ name: 'logout' }"
              :class="$style['dropdown-profile-item']"
            >
              <base-icon is-stroked icon="logout" />
              Log out
            </sidebar-link>
          </base-dropdown-item>
        </div>
      </base-dropdown>
    </div>
  </header>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import logo from "@/assets/logo-named.svg";
import ButtonAnchor from "./ButtonAnchor.vue";
import UserImage from "@/components/UserImage.vue";
import BaseDropdownItem from "@/components/BaseDropdownItem.vue";
import BaseDropdown from "@/components/BaseDropdown.vue";
import BaseIcon from "@/components/icons/BaseIcon.vue";
import MessageBox from "@/components/MessageBox";
import { appBaseUrl, appBoardUrl } from "@/utils/url";
import SidebarLink from "@/components/SidebarLink.vue";

export default {
  components: {
    SidebarLink,
    ButtonAnchor,
    BaseDropdownItem,
    BaseDropdown,
    UserImage,
    BaseIcon,
    MessageBox,
  },
  data() {
    return { logo };
  },
  computed: {
    ...mapState(["onPrem"]),
    ...mapGetters(["user", "isTraining", "isRetryingConnection"]),
    notAvailable() {
      return this.$store.state.crossNotAvailable;
    },
    webappUrl() {
      return this.$route.name === "editSession"
        ? appBoardUrl(this.$route.query.id, "team")
        : appBaseUrl();
    },
    title() {
      return this.isTraining ? "SAFe Trainer Cockpit" : "RTE Cockpit";
    },
    message() {
      if (this.isRetryingConnection) {
        return "Connection not working. Trying to reconnect...";
      }
      return "Connection not working. Something is wrong.";
    },
  },
};
</script>

<style lang="scss" module>
@import "@/_variables-legacy.scss";

.header {
  position: fixed;
  top: 0;
  padding: 0 1.75rem;
  width: calc(100% - 3.5rem);
  z-index: 3;
  background-color: $whiteColor;
  display: flex;
  border-bottom: 1px solid $silverColor;
  align-items: center;
  height: 4rem;
  gap: 1.5rem;
  justify-content: space-between;

  .error-message {
    padding: 0.5rem 2.5rem;
  }

  .header-group {
    display: flex;
    align-items: center;

    > img {
      width: 11rem;
      height: 2rem;
    }

    .dropdown {
      width: 3rem;
      z-index: 2;

      .user-info {
        display: flex;
        flex-direction: column;
        padding: 0.375rem 1.5rem;
        font-weight: bold;

        .user-email {
          font-weight: normal;
          color: $darkGreyColor;
          margin-top: 0.25rem;
        }
      }

      .divider-line {
        border: 1px solid $lightGreyColor;
        width: auto;
      }

      .dropdown-items-wrap {
        font-weight: bold;
        display: flex;
        flex-direction: column;
        gap: 0.5rem;
      }

      .dropdown-profile-item {
        width: 100%;
      }
    }
  }

  .vertical-line {
    border-left: 1px solid $silverColor;
    margin: 0 2rem;
    height: 1.5rem;
  }

  .header-title {
    font-weight: 900;
  }

  .link {
    outline: revert;
    cursor: pointer;
    text-decoration: underline;
    color: $purpleColor;
    background: none;
  }
}
</style>
