<template>
  <p :class="$style['page-loading']">
    <base-icon :class="$style.loading" size="24" icon="loading" />
    <slot></slot>
  </p>
</template>

<script>
import BaseIcon from "@/components/icons/BaseIcon";

export default {
  name: "PageLoading",
  components: {
    BaseIcon,
  },
};
</script>

<style lang="scss" module>
@import "@/styles/loading-icon.scss";

.page-loading {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  margin: 16px auto;
}
</style>
<docs>
A simple spinning icon and a custom text to be shown when a page is loading.

### Example

```jsx
<page-loading>Page is loading</page-loading>
```
</docs>
