import {
  amplitudeApiKey,
  amplitudeDebug,
  environment,
  isCloud,
} from "@/environment";
import { captureMessageWithContext } from "@/sentry";
import store from "@/store";
import * as amplitude from "@amplitude/analytics-browser";

/**
 * Tracks an analytics event. The event data must be an object where all values
 * are either strings in kebab case, numbers, or booleans.
 *
 * @param {Object} params - The parameters for the analytics event.
 * @param {string} params.event - The event name.
 * @param {Object} params.data - The event data.
 */
export function trackEvent(params) {
  const { event, ...data } = params;

  if (amplitudeDebug) {
    console.info("trackEvent", event, data);
  }

  const valid = isValidEventData(data);
  if (!valid && environment !== "production") {
    console.warn("Invalid event data", params);
  }

  if (!valid) {
    captureMessageWithContext("Invalid event data", { data: { params } });
    return;
  }

  if (!shouldTrack()) return;

  amplitude.track(event, data);
}

/**
 * Validates the event data.
 * The data must be an object where all values are either strings in kebab case,
 * numbers, or booleans.
 *
 * @param {Object} data - The event data.
 * @returns {boolean} - Returns true if the data is valid, false otherwise.
 */
function isValidEventData(data) {
  const kebabCaseRegex = /^[a-z0-9]+(-[a-z0-9]+)*$/;

  return Object.values(data).every((v) => {
    // must be kebab case
    if (typeof v === "string") {
      return kebabCaseRegex.test(v);
    }

    // must be a finite number
    if (typeof v === "number" && isFinite(v)) {
      return true;
    }

    // must be a boolean
    if (typeof v === "boolean") {
      return true;
    }

    return false;
  });
}

/**
 * Identifies the user and sends user data to Amplitude for tracking.
 */
export function identifyUser(user, license) {
  if (!amplitudeApiKey) return;

  amplitude.init(amplitudeApiKey, {
    defaultTracking: false,
    serverZone: "EU",
    trackingOptions: { ipAddress: false },
  });

  amplitude.setUserId(user.id);

  const identifyEvent = new amplitude.Identify();
  identifyEvent.set("company", user.company);
  identifyEvent.set("role", user.role);
  identifyEvent.set("type", license.type);
  identifyEvent.set("isLicenseExpired", license.isExpired);
  identifyEvent.set("plan", license.plan);
  identifyEvent.set("language", user.preferredLanguage);

  amplitude.identify(identifyEvent);
}

/**
 * Set any custom amplitude user property
 * (the new value will be added to all future events)
 *
 * @param {("company" | "role" | "plan" | "language")} property - Property name
 * @param {(number | string | boolean | Array<string | number> | Object)} value - Property value
 */
export function setAmplitudeUserProperty(property, value) {
  var identifyEvent = new amplitude.Identify();
  identifyEvent.set(property, value);
  amplitude.identify(identifyEvent);
}

const shouldTrack = () => {
  return isCloud && store.state.license.tracking;
};
