export const wampUrl = window.WAMP_URL || process.env.VUE_APP_WAMP_URL;
export const paymentUrl = window.PAYMENT_URL || process.env.VUE_APP_PAYMENT_URL;
export const isCloud =
  "IS_CLOUD" in window
    ? window.IS_CLOUD === "true"
    : process.env.VUE_APP_IS_CLOUD === "true";
export const webappUrl = window.WEBAPP_URL || process.env.VUE_APP_WEBAPP_URL;
export const authAPIUrl =
  window.AUTH_API_URL || process.env.VUE_APP_AUTH_API_URL;
export const almauditAPIUrl =
  window.ALMAUDIT_API_URL || process.env.VUE_APP_ALMAUDIT_API_URL;
export const sentryDsn = window.SENTRY_DSN || process.env.VUE_APP_SENTRY_DSN;
export const gaKey = window.GA_KEY || process.env.VUE_APP_GA_KEY;
export const environment =
  window.ENVIRONMENT || process.env.VUE_APP_ENVIRONMENT;
export const intercomId = window.INTERCOM_ID || process.env.VUE_APP_INTERCOM_ID;
export const intercomApi =
  window.INTERCOM_API || process.env.VUE_APP_INTERCOM_API;
export const amplitudeApiKey =
  window.AMPLITUDE_API_KEY || process.env.VUE_APP_AMPLITUDE_API_KEY;
export const amplitudeDebug =
  window.AMPLITUDE_DEBUG || process.env.VUE_APP_AMPLITUDE_DEBUG;
export const featureProxyUrl =
  window.FEATURE_PROXY_URL || process.env.VUE_FEATURE_PROXY_URL;
export const featureProxySecret =
  window.FEATURE_PROXY_SECRET || process.env.VUE_FEATURE_PROXY_SECRET;
