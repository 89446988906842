<template>
  <aside :class="[$style[type], $style['message-box']]" role="note">
    <base-icon :icon="type" size="24" :class="$style['message-icon']" />
    <!-- @slot The text displayed inside the info box -->
    <div>
      <slot></slot>
    </div>
  </aside>
</template>

<script>
import BaseIcon from "@/components/icons/BaseIcon.vue";

export default {
  components: {
    BaseIcon,
  },
  props: {
    type: {
      validator(type) {
        return ["error", "warning", "success", "info"].includes(type);
      },
      default: "info",
    },
  },
};
</script>

<style lang="scss" module>
@import "@/_variables-legacy.scss";

.message-box {
  display: flex;
  align-items: center;
  gap: 1.125rem;
  padding: 1.125rem 1.375rem;
  margin: auto;
  background-color: $lightBlueColor;
  border-radius: 8px;
  box-sizing: border-box;

  svg {
    flex: 0 0 auto;
  }
}

.info {
  border-color: $darkBlueColor;

  .message-icon {
    fill: $darkBlueColor;
  }
}

.error {
  background-color: $lightRedColor;

  .message-icon {
    fill: $redColor;
  }
}

.warning {
  background-color: $lightOrangeColor;

  .message-icon {
    fill: $orangeColor;
  }
}

.success {
  background-color: $lightGreenColor;

  .message-icon {
    fill: $greenColor;
  }
}
</style>

<docs>
  Message box to inform user about something on the page. Text is passed through a slot.

  ### Examples

  A default info box:
  ```jsx
  <message-box>
    Description which is helpful to the user.
    This could potentially be a really long message.
  </message-box>
  ```

  A success message:
  ```jsx
  <message-box type="success">Operation completed successfully.</message-box>
  ```

  A warning message:
  ```jsx
  <message-box type="warning">This operation is irreversible.</message-box>
  ```

  An error message:
  ```jsx
  <message-box type="error">Could not delete the user.</message-box>
  ```

  With rich content:
  ```jsx
  <message-box><p>Be sure to check the <a href="https://example.com">documentation</a>.</p> <br /><base-button class="small">Hide message</base-button></message-box>
  ```
</docs>
