import { RemoteCaller } from "./remoteCaller";
import { sortStickies } from "@/components/session/boardUtils";
import {
  colorOrFallback,
  dateToUnixTimestamp,
  generateID,
  rgbToHex,
  unixTimestampToDate,
} from "@/utils";

export default class SessionHandler {
  constructor(context, sessionID, apiVersion = 6) {
    this.sessionID = sessionID;
    this.remote = new RemoteCaller(
      context,
      "piplanning",
      apiVersion,
      "session"
    );
  }

  delete() {
    return this.remote.call("delete", [this.sessionID]);
  }

  getXlsx() {
    return this.remote.call("get_xlsx", [this.sessionID]);
  }

  importFile(method, dataBase64) {
    return this.remote.call(method, [this.sessionID, dataBase64]);
  }

  getName() {
    return this.remote.call("get_name", [this.sessionID]);
  }

  setName(name) {
    return this.remote.call("set_name", [this.sessionID, name]);
  }

  async getPlanningDates() {
    const dates = await this.remote.call("get_planning_dates", [
      this.sessionID,
    ]);

    return {
      start: unixTimestampToDate(dates.start),
      end: unixTimestampToDate(dates.end),
    };
  }

  setPlanningDates(start, end) {
    return this.remote.call("set_planning_dates", [
      this.sessionID,
      dateToUnixTimestamp(start),
      dateToUnixTimestamp(end),
    ]);
  }

  getSettings() {
    return this.remote.call("get_settings", [this.sessionID]);
  }

  async setSettings(settings) {
    for (const key in settings) {
      const value = settings[key];
      await this.remote.call("set_settings", [
        this.sessionID,
        key,
        value === undefined || value === false ? false : value,
      ]);
    }
  }

  getBoards() {
    return this.remote.call("get_boards", [this.sessionID]);
  }

  getParticipatingTeamIds() {
    return this.remote.call("get_participating_users", [this.sessionID]);
  }

  setParticipatingTeamIds(teamIds) {
    return this.remote.call("set_participating_users", [
      this.sessionID,
      teamIds,
    ]);
  }

  getTeams() {
    return this.remote.call("get_teams", [this.sessionID]);
  }

  getArts() {
    return this.remote.call("get_arts", [this.sessionID]);
  }

  getStickyTypes() {
    return this.remote.call("get_sticky_types", [this.sessionID]);
  }

  getIterations() {
    return this.remote.call("get_iterations", [this.sessionID]);
  }

  setIterations(iterations) {
    return this.remote.call("set_iterations", [this.sessionID, iterations]);
  }

  getLinkTypes() {
    return this.remote.call("get_link_types", [this.sessionID]);
  }

  getFlexTypes() {
    return this.remote.call("get_flexboard_types", [this.sessionID]);
  }

  getFlexBackgrounds() {
    return this.remote.call("get_flexboard_backgrounds", []);
  }

  createFlexType(name, background) {
    return this.remote.call("create_flexboard_type", [
      this.sessionID,
      name,
      background,
    ]);
  }

  archiveSession(sessionID) {
    return this.remote.call("archive", [sessionID]);
  }

  unarchiveSession(sessionID) {
    return this.remote.call("unarchive", [sessionID]);
  }

  updateFlexType(id, name, background) {
    return this.remote.call("update_flexboard_type", [id, name, background]);
  }

  deleteFlexType(id) {
    return this.remote.call("delete_flexboard_type", [id]);
  }

  hasStickiesOfType(id) {
    return this.remote.call("has_stickies_of_type", [id]);
  }

  createLinkType(fromId, toId) {
    return this.remote.call("create_link_type", [this.sessionID, fromId, toId]);
  }

  updateLinkType(id, fromId, toId) {
    return this.remote.call("alter_link_type", [
      id,
      {
        from_sticky_type: fromId,
        to_sticky_type: toId,
      },
    ]);
  }

  deleteLinkType(id) {
    return this.remote.call("delete_link_type", [id]);
  }

  async loadStickyTypes(boardType, almHandler) {
    const types = await this.getStickyTypes();
    const stickies = types.filter((sticky) =>
      sticky.available_board_types.includes(boardType)
    );
    const loader = await this.stickyLoaders[boardType](almHandler);
    stickies.forEach((sticky) => loader(sticky));
    return sortStickies(stickies, boardType);
  }

  async loadIterations() {
    const iterations = await this.getIterations();
    return iterations.map((iter) => ({
      id: iter.id,
      name: iter.name,
      number: iter.number,
      order: iter.order,
      start: unixTimestampToDate(iter.start),
      end: unixTimestampToDate(iter.end),
      teams: [],
      nameError: "",
      dateError: "",
    }));
  }

  async loadFlexBoards() {
    const stickies = await this.getStickyTypes();
    const boards = await this.getFlexTypes();
    return boards.map((board) => ({
      id: board.id,
      name: board.name,
      background: board.background,
      error: null,
      stickyTypes: stickies
        .filter(
          (s) =>
            s.origin_board_type === "flex" &&
            s.origin_flexboard_type === board.id
        )
        .map((s) => {
          return {
            id: s.id,
            name: s.name,
            color: rgbToHex(s.color[0], s.color[1], s.color[2]),
            index: s.index,
            frontend_id: generateID(),
            error: "",
            functionality: s.functionality,
          };
        })
        .sort((a, b) => a.index - b.index),
    }));
  }

  saveStickyType(sticky, data) {
    if ("id" in sticky) {
      return this.updateStickyType(sticky.id, data);
    }
    return this.createStickyType(data).then((id) => (sticky.id = id));
  }

  createStickyType(sticky, almType) {
    return this.remote.call("create_sticky_type", [
      this.sessionID,
      sticky.name,
      sticky.color,
      sticky.origin_board_type,
      almType,
      sticky,
    ]);
  }

  updateStickyType(id, data) {
    return this.remote.call("update_sticky_type", [id, data]);
  }

  deleteStickyType(id) {
    return this.remote.call("delete_sticky_type", [id]);
  }

  deleteUnusedStickyType(id) {
    return this.remote.call("delete_unused_sticky_type", [id]);
  }

  stickyLoaders = {
    program: () => this.baseStickyLoader("program"),
    solution: () => this.baseStickyLoader("solution"),
    risk: () => this.baseStickyLoader("risk"),
    team: async (almHandler) => {
      const isAlm = almHandler && almHandler.getAlmTypes;
      const almTypes = isAlm ? await almHandler.getAlmTypes() : [];
      return this.baseStickyLoader("team", (sticky) => {
        Object.assign(sticky, {
          issueType: -1,
          serverData: [],
          availableIssueFields: [],
          checking: false,
          hasNameError: false,
          hasServerError: false,
          hasWarning: false,
        });
        if (isAlm) {
          const almType = almTypes.find(
            (almType) => almType.id === sticky.alm_type
          );
          Object.assign(sticky, almHandler.getTeamBoardAlmType(almType));
        }
      });
    },
    backlog: async (almHandler) => {
      const isAlm = almHandler && almHandler.getAlmTypes;
      const almTypes = isAlm ? await almHandler.getAlmTypes() : [];
      return this.baseStickyLoader("backlog", (sticky) => {
        sticky.alt_color = colorOrFallback(sticky.alt_color);
        sticky.alt_color = Object.prototype.hasOwnProperty.call(
          sticky,
          "alt_color"
        )
          ? rgbToHex(
              sticky.alt_color[0],
              sticky.alt_color[1],
              sticky.alt_color[2]
            )
          : sticky.color;
        Object.assign(sticky, {
          project: -1,
          issueType: -1,
          selectedField: -1,
          availableIssueTypes: [],
          availableIssueFields: {},
          availableReleases: [],
          labels: "",
          tags: [],
          release: -1,
          adoFields: [],
          hasNameError: false,
          hasIssueError: false,
          issueLoad: false,
          fieldLoad: false,
        });
        if (isAlm) {
          const almType = almTypes.find(
            (almType) => almType.id === sticky.alm_type
          );
          Object.assign(sticky, {
            project: almType.project_id || -1,
            issueType: almType.issue_type,
            ...almHandler.getBacklogBoardAlmType(sticky, almType),
          });
        }
      });
    },
    solution_backlog: async (almHandler) => {
      const isAlm = almHandler && almHandler.getAlmTypes;
      const almTypes = isAlm ? await almHandler.getAlmTypes() : [];
      return this.baseStickyLoader("solution_backlog", (sticky) => {
        sticky.alt_color = colorOrFallback(sticky.alt_color);
        sticky.alt_color = Object.prototype.hasOwnProperty.call(
          sticky,
          "alt_color"
        )
          ? rgbToHex(
              sticky.alt_color[0],
              sticky.alt_color[1],
              sticky.alt_color[2]
            )
          : sticky.color;
        Object.assign(sticky, {
          project: -1,
          issueType: -1,
          selectedField: -1,
          availableIssueTypes: [],
          availableIssueFields: {},
          availableReleases: [],
          labels: "",
          tags: [],
          release: -1,
          adoFields: [],
          hasNameError: false,
          hasIssueError: false,
          issueLoad: false,
          fieldLoad: false,
        });
        if (isAlm) {
          const almType = almTypes.find(
            (almType) => almType.id === sticky.alm_type
          );
          Object.assign(sticky, {
            project: almType.project_id || -1,
            issueType: almType.issue_type,
            ...almHandler.getBacklogBoardAlmType(sticky, almType),
          });
        }
      });
    },
  };

  baseStickyLoader(boardType, process) {
    return (sticky) => {
      this.initSticky(sticky);
      sticky.frontend_id = sticky.id;
      sticky.color = colorOrFallback(sticky.color);
      sticky.color = rgbToHex(
        sticky.color[0],
        sticky.color[1],
        sticky.color[2]
      );
      if (sticky.origin_board_type !== boardType) {
        sticky.disabled_editing = true;
      }
      if (process) {
        process(sticky);
      }
      return sticky;
    };
  }

  initSticky(sticky) {
    const abt = sticky.available_board_types;
    const origin = sticky.origin_board_type;
    sticky.available_board_types = sticky.available_board_types || [];
    if (!sticky.available_board_types.includes(origin)) {
      sticky.available_board_types.push(origin);
    }
    let i = 0;
    while (i < sticky.available_board_types.length) {
      if (sticky.available_board_types[i].trim() === "") {
        sticky.available_board_types.splice(i, 1);
      } else {
        i++;
      }
    }
    if (abt !== sticky.available_board_types) {
      this.updateStickyType(sticky.id, {
        available_board_types: sticky.available_board_types,
      });
    }
  }
}
