import { featureToggles } from "@/featureToggles";

export function isFeatureEnabled(route, feature) {
  return (
    !isFeatureEnabledInQuery(route, "!" + feature) &&
    (isFeatureEnabledInQuery(route, feature) ||
      featureToggles.isEnabled(feature))
  );
}

export function parseUrlWithoutRouter() {
  const hash = document.location.hash;
  const queryStart = hash.indexOf("?");
  if (queryStart < 0) {
    return {};
  }
  const params = new URLSearchParams(hash.substring(queryStart));
  const extractedParams = {};
  for (const [key, value] of params) {
    if (extractedParams[key]) {
      extractedParams[key].push(value);
    } else {
      extractedParams[key] = [value];
    }
  }
  return extractedParams;
}

export function getFeatureQuery(route) {
  if (!route?.query?.feature) {
    return {};
  }
  return { feature: route.query.feature };
}

function isFeatureEnabledInQuery(route, feature) {
  if (!route?.query?.feature) {
    return false;
  }
  if (Array.isArray(route.query.feature)) {
    return route.query.feature.includes(feature);
  }
  return route.query.feature === feature;
}
