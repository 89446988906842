<template>
  <div
    :id="id"
    class="search-select"
    @focusin="showSelect"
    @focusout="maybeHideSelect"
  >
    <search-box
      ref="search"
      immediate
      role="combobox"
      :placeholder="placeholder"
      :loading="loading"
      :aria-labelledby="ariaLabelledby"
      :aria-label="ariaLabel"
      :aria-controls="id + 'select'"
      :aria-expanded="selectVisible ? 'true' : 'false'"
      :aria-autocomplete="'list'"
      :aria-activedescendant="currentItemId"
      @search-value="$emit('search', $event)"
      @keydown.down="$refs.results.moveCurrent(1)"
      @keydown.up="$refs.results.moveCurrent(-1)"
      @keydown.enter="enter"
      @keydown.esc="handleEsc"
    />
    <div class="select-wrap">
      <select-list
        v-show="selectVisible"
        :id="id + 'select'"
        ref="results"
        v-slot="{ item }"
        role="listbox"
        item-role="option"
        :items="values"
        :no-items-message="noValuesMessage"
        :no-initial-current="true"
        @select="select"
        @current-id-set="currentItemId = $event"
      >
        <slot :item="item">{{ item }}</slot>
      </select-list>
    </div>
  </div>
</template>

<script>
import SearchBox from "@/components/SearchBox";
import SelectList from "@/components/SelectList";

export default {
  name: "SearchSelectBox",
  components: { SearchBox, SelectList },
  props: {
    id: {
      type: String,
      required: true,
    },
    placeholder: {
      type: String,
      required: true,
    },
    values: {
      type: Array,
      required: true,
    },
    noValuesMessage: {
      type: String,
      default: "No items found",
    },
    keepFocusOnSelect: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    ariaLabelledby: { type: String },
    ariaLabel: { type: String },
  },
  data() {
    return {
      selectVisible: false,
      currentItemId: undefined,
    };
  },
  methods: {
    showSelect() {
      this.selectVisible = true;
    },
    hideSelect() {
      this.selectVisible = false;
      this.$emit("blur");
    },
    maybeHideSelect(event) {
      if (!this.$el.contains(event.relatedTarget)) {
        this.hideSelect();
      }
    },
    enter() {
      if (!this.loading) {
        this.$refs.results.select();
      }
    },
    select(event) {
      if (!this.keepFocusOnSelect) {
        this.$refs.search.blur();
      }
      this.$emit("select", event);
    },
    handleEsc() {
      if (this.selectVisible) {
        this.hideSelect();
      } else {
        this.$refs.search.blur();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/_variables-legacy.scss";

.search-select {
  position: relative;
  width: 100%;
  font-size: 1.25rem;

  .select-wrap {
    position: absolute;
    margin-top: -3px;
    z-index: 1;
    width: 100%;
  }
}
</style>
<docs>
Displays a search box and the search results.

### Examples

```vue
<template>
  <div>
    <search-select-box id="search" placeholder="query" @search="search" @select="selected=$event"
                       :values="result" v-slot="{item}">
      {{ item.name }}
    </search-select-box>
    Selected: {{ selected }}
  </div>
</template>
<script>
export default {
  data() {
    return {
      result: [],
      selected: null
    };
  },
  methods: {
    search(query) {
      this.result = query.length > 3 ? [] : [{ name: "first" }, { name: query }, { name: "last" }];
    }
  }
};
</script>
```
</docs>
