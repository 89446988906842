import { createFocusTrap } from "focus-trap";

/**
 * Traps the focus inside an element. Usage: `v-focus-trap`
 *
 * By default, the trap is removed when the user clicks outiside of the trapped element.
 * This can be disabled by passing `{ disableOnOutsideClick: false }` as a value.
 */
export function focusTrap() {
  let focusTrap = null;
  let trappedEl = null;

  // remove the trap when clicking outside the trapped element
  const handleOutsideClick = (event) => {
    if (!trappedEl) return;

    const target = event.target;
    const isChild = isChildOf(target, trappedEl);

    if (!isChild) {
      focusTrap?.deactivate();
      document.removeEventListener("mousedown", handleOutsideClick, {
        capture: true,
      });
    }
  };

  return {
    inserted(el, { value }) {
      if (focusTrap) return;

      const disableOnOutsideClick = value?.disableOnOutsideClick ?? true;
      if (disableOnOutsideClick) {
        trappedEl = el;
        document.addEventListener("mousedown", handleOutsideClick, {
          capture: true,
        });
      }

      focusTrap = createFocusTrap(el, {
        initialFocus: false,
        ...(value || {}),
      });
      focusTrap?.activate();
    },
    unbind() {
      document.removeEventListener("mousedown", handleOutsideClick, {
        capture: true,
      });
      focusTrap?.deactivate();
      focusTrap = null;
    },
  };
}

function isChildOf(child, parent) {
  return parent.contains(child);
}
