<template>
  <fieldset
    :class="{
      [$style['validated-input']]: true,
      [$style['prefix-wrapper']]: prefix,
    }"
  >
    <label v-if="label" :for="id">
      {{ label }}
      <slot></slot>
    </label>
    <span v-if="prefix" :class="$style['prefix']">{{ prefix }}</span>
    <base-input
      :id="id"
      ref="input"
      v-model="inputValue"
      v-autofocus="autofocus"
      :class="{ [$style.loading]: loading }"
      v-bind="$attrs"
      :show-errors="wasChanged"
      v-on="listeners"
    />
    <span v-if="errorMessage" :class="$style.error">{{ errorMessage }}</span>
  </fieldset>
</template>

<script>
import BaseInput from "./BaseInput";

export default {
  components: { BaseInput },
  inheritAttrs: false,
  props: {
    /**
     * If true, autofocuses the input on mount
     */
    autofocus: {
      required: false,
      type: Boolean,
      default: false,
    },
    /**
     * The text for the label in front of the input
     */
    label: {
      required: false,
      type: String,
      default: undefined,
    },
    /**
     * The id of the input. It maps directly to the id attribute of the `<input>` element.
     */
    id: {
      required: true,
      type: String,
    },
    prefix: {
      required: false,
      type: String,
    },
    /**
     * The value of the input field, as set by v-model
     */
    value: {
      type: [String, Number],
      default: "",
    },
    /**
     * The event that should trigger the validation. Default is `input`
     */
    validateOn: {
      type: String,
      default: "input",
    },
    /**
     * A custom validation function. It should  return:
     * * An object with `success` set to `true` if the validation succeeds
     * * An object with `success` set to `false` if the validation fails and an error message to be displayed in `error`
     */
    customValidator: {
      type: Function,
      default: () => ({ success: true }),
    },
  },
  data() {
    return {
      wasChanged: false,
      errorMessage: "",
      loading: false,
    };
  },
  computed: {
    inputValue: {
      get() {
        return this.value;
      },
      set(newValue) {
        /**
         * On value change, bubble up the value to make the component work with `v-model`
         * @property {string} value The updated value of the input
         */
        this.$emit("input", newValue);
      },
    },
    listeners() {
      const listeners = { ...this.$listeners, invalid: this.setErrorMessage };
      listeners[this.validateOn] = (event) => {
        this.validate();
        this.$emit(this.validateOn, event);
      };

      return listeners;
    },
  },
  methods: {
    async validate() {
      this.wasChanged = true;
      this.errorMessage = "";
      this.$refs.input.setCustomValidity("");
      if (this.$refs.input.checkValidity()) {
        this.loading = true;
        let result;
        try {
          result = await this.customValidator(this.$refs.input.$el.value);
        } finally {
          this.loading = false;
        }
        if (result.success) {
          return true;
        }
        this.$refs.input.setCustomValidity(result.error);
        this.$refs.input.checkValidity();
      }
      return false;
    },
    setErrorMessage({ target }) {
      this.errorMessage = this.calcErrorMessage(
        target.validity,
        target.validationMessage
      );
    },
    calcErrorMessage(validity, validationMessage) {
      const fieldName = this.label || this.id;
      if (validity.valueMissing) {
        return `${fieldName} is required.`;
      }
      if (validity.patternMismatch) {
        return `${fieldName} is in the wrong format.`;
      }
      // minlength and maxlength cannot be tested by automatically:
      // https://html.spec.whatwg.org/multipage/form-control-infrastructure.html#attr-fe-minlength
      if (validity.tooShort) {
        const length = this.$attrs.minlength;
        return `${fieldName} must be at least ${length} characters long.`;
      }
      if (validity.tooLong) {
        const length = this.$attrs.maxlength;
        return `${fieldName} must be at most ${length} characters long.`;
      }
      if (validity.rangeUnderflow) {
        const min = this.$attrs.min;
        return `${fieldName} must be at least ${min} or greater.`;
      }
      return validationMessage || `${fieldName} is not valid.`;
    },
  },
};
</script>

<style lang="scss" module>
@import "@/_variables-legacy.scss";

.validated-input {
  display: flex;
  flex-direction: column;
  text-align: start;
  gap: 8px;
  width: 100%;
  box-sizing: border-box;
  border-width: 0;
  margin: 16px 0;
  padding-left: 0;
  padding-right: 0;

  .loading {
    background-image: url(~@/assets/spinner.gif);
    background-size: 2em;
    background-position: right center;
    background-repeat: no-repeat;
  }

  label {
    font-size: 1.25rem;
    color: $darkGreyColor;
  }

  .error {
    color: $redColor;
    font-size: smaller;
  }

  &.prefix-wrapper {
    position: relative;

    input {
      padding-left: 40px !important;
    }
  }

  .prefix {
    position: absolute;
    height: 38px;
    left: 10px;
    top: 23px;
    display: flex;
    align-items: center;
    font-size: 14px;
    width: 30px;
  }
}
</style>

<docs>
A basic input for forms

### Examples

With a label:

```jsx
<validated-input id="username" label="Username" />
```

With a label and additional information:

```jsx
<validated-input id="username" label="Username">
  <hover-box>The name of a user</hover-box>
</validated-input>
```

Without a label:

```jsx
<validated-input id="tel" placeholder="Telephone" />
```

It can be used in conjunction with `v-model`:

```vue
<template>
  <div>
    Hello, {{ name }}!
    <validated-input id="name" v-model="name" label="Name" />
  </div>
</template>
<script>
export default {
  data() {
    return {
      name: "Jim"
    };
  }
};
</script>
```

With validation errors:

```vue
<template>
  <div>
    <validated-input
      id="name"
      v-model="name"
      label="Name"
      ref="nameInput"
      required
    />
    <validated-input
      id="account"
      v-model="account"
      label="Account ID"
      ref="accountInput"
      pattern="pips-.+"
      minlength="7"
      maxlength="15"
    />
  </div>
</template>
<script>

export default {
  data() {
    return {
      name: "",
      account: "something else"
    };
  },
  async mounted() {
    this.$refs.nameInput.validate();
    this.$refs.accountInput.validate();
  }
};
</script>
```

With a custom validator and validation on blur:

```vue
<template>
  <div>
    This input will reject names starting with J
    <validated-input
      id="name"
      v-model="name"
      label="Name"
      validate-on="blur"
      :custom-validator="checkName"
    />
  </div>
</template>
<script>
export default {
  data() {
    return {
      name: ""
    };
  },
  methods: {
    checkName() {
      if (this.name.toLowerCase().startsWith("j")) {
        return { success: false, error: "Names starting with J are not allowed" };
      }
      return { success: true };
    }
  }
};
</script>
```

</docs>
