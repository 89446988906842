<template>
  <base-button :class="$style['action-button']" v-on="$listeners">
    <base-icon :icon="iconName" />
    <slot></slot>
  </base-button>
</template>

<script>
import BaseIcon from "@/components/icons/BaseIcon.vue";
import BaseButton from "@/components/BaseButton.vue";

export default {
  components: {
    BaseIcon,
    BaseButton,
  },
  props: {
    action: {
      type: String,
      required: true,
      validator: (action) => ["edit", "delete", "multi"].includes(action),
    },
  },
  data() {
    return {
      iconAliases: {
        delete: "trash",
      },
    };
  },
  computed: {
    iconName() {
      return this.iconAliases[this.action] || this.action;
    },
  },
};
</script>

<docs>
  ### Examples

  Delete action button:
  ```jsx
  <action-button action="delete"></action-button>
  ```

  Edit action button:
  ```jsx
  <action-button action="edit"></action-button>
  ```
</docs>

<style lang="scss" module>
.action-button {
  padding: 0;
  margin: 0;
  min-width: 35px;
  height: 30px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}
</style>
