<template>
  <a
    :class="{
      [$style.button]: true,
      [$style.secondary]: secondary,
    }"
  >
    <!-- @slot The label on the button -->
    <slot></slot>
  </a>
</template>

<script>
export default {
  props: {
    secondary: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style lang="scss" module>
@import "@/styles/button-legacy.scss";

a.button {
  @include button;
  text-decoration: none;
  display: inline-block;
  text-align: center;

  &:hover,
  &:focus-visible {
    color: $darkBlueColor;
    background-color: $whiteColor;

    &.secondary {
      border-width: 3px;
      padding: 1rem 3rem;
    }
  }
}
</style>

<docs>
  Anchor link with styling of button.

  ### Examples

  Anchor button:

  ```jsx
  <button-anchor href="https://piplanning.io" target="_blank">Click me!</button-anchor>
  ```

  Small anchor button:

  ```jsx
  <button-anchor class="small" href="https://piplanning.io" target="_blank">Click me!</button-anchor>
  ```
</docs>
