<template>
  <button
    :disabled="isDisabled"
    :class="{
      [$style.button]: true,
      [$style.warning]: warning,
      [$style.validating]: validateForm && wasClicked,
      [$style.secondary]: secondary,
      [$style.tertiary]: tertiary,
    }"
    v-on="listeners"
  >
    <!-- @slot The label on the button -->
    <base-icon
      v-if="loading"
      data-testid="loading-icon"
      :class="$style['loading']"
      size="24"
      icon="loading"
    />
    <slot></slot>
  </button>
</template>

<script>
import BaseIcon from "@/components/icons/BaseIcon.vue";

export default {
  components: {
    BaseIcon,
  },
  props: {
    /**
     * Highlight the button with warning colors to indicate a dangerous operation.
     */
    warning: {
      type: Boolean,
      default: false,
    },
    /**
     * When set to `true`, the button will be disabled.
     */
    disabled: {
      type: Boolean,
      default: false,
    },
    validateForm: {
      type: Boolean,
      default: false,
    },
    /**
     * When set to `true`, use the secondary style for the button.
     */
    secondary: {
      type: Boolean,
      default: false,
    },
    /**
     * When set to `true`, use the tertiary (flat) style for the button.
     */
    tertiary: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      loading: false,
      wasClicked: false,
    };
  },
  computed: {
    listeners() {
      return { ...this.$listeners, click: this.fireClick };
    },
    isDisabled() {
      return this.loading || this.disabled;
    },
  },
  methods: {
    async fireClick(event) {
      this.wasClicked = true;
      const { click } = this.$listeners;
      if (!click || (this.validateForm && !this.$el.form.reportValidity())) {
        return;
      }
      const clicked = click(event);
      if (clicked && clicked.then) {
        this.loading = true;
        try {
          await clicked;
        } finally {
          this.loading = false;
        }
      }
    },
  },
};
</script>

<style lang="scss" module>
@import "@/styles/button-legacy.scss";
@import "@/styles/loading-icon.scss";

.button {
  @include button;

  .loading {
    position: absolute;
    left: 12px;
    top: 18px;
  }

  &:global(.small) {
    .loading {
      left: 8px;
      top: 8px;
      width: 20px;
    }
  }

  &:global(.tiny) {
    .loading {
      left: 4px;
      top: 1px;
      width: 12px;
    }
  }

  &:hover:enabled,
  &:focus-visible:enabled {
    color: $darkBlueColor;
    background-color: $whiteColor;
  }

  &.secondary {
    background-color: $whiteColor;
    color: $darkBlueColor;
    border-width: 1px;
    padding: calc(1rem + 2px) calc(3rem + 2px);

    &:hover:enabled,
    &:focus-visible:enabled {
      border-width: 3px;
      padding: 1rem 3rem;
    }

    &:disabled {
      color: $greyColor;
      background-color: $whiteColor;
    }
  }

  &.tertiary {
    background: none;
    color: $darkBlueColor;
    border-width: 0;
    padding: 0;
    min-width: 0;

    &:hover:enabled,
    &:focus-visible:enabled {
      color: $purpleColor;
    }

    &:disabled {
      color: $greyColor;
    }
  }

  &.warning {
    background-color: $darkRedColor;
    border-color: $darkRedColor;
    color: $whiteColor;

    &:hover:enabled,
    &:focus-visible:enabled {
      color: $darkRedColor;
      background-color: $whiteColor;
    }

    &.secondary {
      background-color: $whiteColor;
      color: $darkRedColor;

      &:disabled {
        color: $greyColor;
        background-color: $whiteColor;
      }
    }
  }

  &:disabled {
    cursor: default;
    background-color: $greyColor;
    border-color: $greyColor;
  }
}
</style>

<docs>
  A basic button with the appropriate styles. All attributes for a button are
  passed through to the HTML `button` element.

  If there's an async click handler, a spinner will be shown during the execution.

  If the `validate-form` attribute is set, the form will be validated on click,
  if the form is invalid, the click action is not executed.

  ### Examples

  Simple button:

  ```jsx
  <base-button>Click me!</base-button>
  ```

  A warning button:

  ```jsx
  <base-button warning>Delete this?</base-button>
  ```

  A disabled button:

  ```jsx
  <base-button disabled>Click me!</base-button>
  ```

  A secondary button:

  ```jsx
  <base-button secondary>Cancel</base-button>
  ```

  A tertiary button:

  ```jsx
  <base-button tertiary>Cancel</base-button>
  ```

  A secondary disabled button:

  ```jsx
  <base-button secondary disabled>Cancel</base-button>
  ```
  A warning secondary button:

  ```jsx
  <base-button warning secondary>Cancel</base-button>
  ```

  A warning secondary disabled button:

  ```jsx
  <base-button warning secondary disabled>Cancel</base-button>
  ```

  A small button:

  ```jsx
  <base-button class="small">This is small</base-button>
  ```



  Normal and small button with loading indicator (click required):
  ```vue
  <template>
    <div class="button-wrap">
      <base-button @click="save">Save Team</base-button>
      <base-button class="small" @click="save">Save Team</base-button>
    </div>
  </template>
  <script>
  export default {
    methods: {
      save() {
        return new Promise(resolve => setTimeout(resolve, 5000))
      },
    }
  }
  </script>
  <style lang="scss">
  .button-wrap {
    display: flex;
    gap: 24px;
  }
  </style>
  ```
</docs>
